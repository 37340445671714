import { Component, Input } from '@angular/core';

@Component({
  selector: 'ei-heading',
  templateUrl: './heading.component.html',
  styleUrls: ['./heading.component.scss'],
})
export class HeadingComponent {
  @Input() header: string;
  @Input() subHeader: string;
  @Input() dark: boolean;
}
