import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ContactPage } from '../../models/contact-form.model';
import { FormSubmissionStatus } from '../../models/form-submission-status.model';
import { ProfilePreview } from '../../models/profile.model';
import { ContactFormService } from '../../services/contact-form.service';

@Component({
  selector: 'ei-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactFormComponent implements OnInit, OnDestroy {
  form: FormGroup;

  status: FormSubmissionStatus;
  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  contactPerson: ProfilePreview = {
    name: 'Nadine Andersson',
    position: $localize`Reception secretariat and data protection`,
    pictureUrl: 'assets/images/ENGELHARDT-1003544.jpg',
  };

  constructor(private _fb: FormBuilder, private _contactFormService: ContactFormService) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      firstName: this._fb.control(null, Validators.required),
      lastName: this._fb.control(null, Validators.required),
      street: this._fb.control(null, Validators.required),
      postalcode: this._fb.control(null, Validators.required),
      place: this._fb.control(null, Validators.required),
      email: this._fb.control(null, Validators.required),
      phone: this._fb.control(null, Validators.required),
      message: this._fb.control(null, Validators.required),
      gdprCompliant: this._fb.control(null, Validators.requiredTrue),
    });
  }

  submit(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this._contactFormService
        .sendContactDetails(this.form.value)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe({
          next: (): void => {
            this.status = FormSubmissionStatus.Success;
          },
          error: (): void => {
            this.status = FormSubmissionStatus.Failed;
          },
        });
    }
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }
}
