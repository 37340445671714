export const environment = {
  production: true,
  contentfulSpaceId: 'p247mzb1s5hq',
  contentfulApiAccessToken: 'Acl3BGGuveRY46jy4irpUTicq15XWFA_ewwzosw1nfc',
  contentfulEnvironment: 'staging',
  mapAPIKey: 'AIzaSyDmXQ8FaEUoK2d6o1-Qjcbk_ghQLqBvFMo',
  appUrl: 'https://staging.engelhardt-kg.com',
  clientApiUrl: '/client/api/v1',
  adminApiUrl: '/admin/api/v1',
  fileSizeLimit: 5000000,
};
