import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormControl, FormGroup, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilitiesService } from '../../services/utilities.service';

@Component({
  selector: 'ei-range-filter',
  templateUrl: './range-filter.component.html',
  styleUrls: ['./range-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RangeFilterComponent implements ControlValueAccessor, OnInit, OnDestroy {
  private _unsubscribe$ = new Subject();

  inputControl = new FormControl();
  open: boolean;
  form: FormGroup;

  @Input() placeholder: string;

  @ViewChild('rangeFilter', { read: ElementRef, static: false }) rangeFilter: ElementRef;

  constructor(
    @Self() @Optional() public control: NgControl,
    private _fb: FormBuilder,
    private _utilitiesService: UtilitiesService,
  ) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.form = this._fb.group(
      {
        from: [null],
        to: [null],
      },
      {
        validators: this.rangeValidator,
      },
    );

    this.form.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((result: { from: string; to: string }) => {
      this.onChangeFn(result);
    });

    this._utilitiesService.documentClickedTarget$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((target: HTMLElement): void => {
        if (!this.rangeFilter.nativeElement.contains(target)) {
          this.onClickedOutside();
        }
      });
  }

  rangeValidator(formGroup: FormGroup): void {
    if (parseFloat(formGroup.controls.from.value) > parseFloat(formGroup.controls.to.value)) {
      formGroup.controls.from.setErrors({
        fromNumberLargerThanTo: true,
      });
    } else {
      formGroup.controls.from.setErrors(null);
    }
  }

  get formControls(): {
    from: FormControl;
    to: FormControl;
  } {
    return {
      from: this.form.controls.from as FormControl,
      to: this.form.controls.to as FormControl,
    };
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  clear(formControlName: 'from' | 'to'): void {
    this.formControls[formControlName].setValue(null);
  }

  removeItem(formControlName: number): void {
    this.formControls[formControlName].setValue(null);
  }

  onChangeFn = (_: any) => {};
  onTouchedFn = () => {};
  writeValue(obj: any): void {
    if (!obj && this.form) {
      this.form.reset();
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  openPanel = (): void => {
    this.open = true;
  };

  onClickedOutside(): void {
    this.open = false;

    if (this.formControls.from.invalid) {
      this.formControls.from.setValue(null);
    }

    if (this.formControls.to.invalid) {
      this.formControls.to.setValue(null);
    }
  }
}
