<div id="ei-news-list">
  <div class="news-list">
    <div class="news-list-item" *ngFor="let item of news">
      <ei-news-preview [news]="item" [showMoreButtonOnDesktop]="showMoreButtonOnDesktop"></ei-news-preview>
    </div>
  </div>
  <div class="more" *ngIf="showAll">
    <ei-button color="secondaryWhite" (buttonClick)="onMoreClick()" i18n>See all</ei-button>
  </div>
</div>
