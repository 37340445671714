import { Component, Input } from '@angular/core';

@Component({
  selector: 'ei-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss'],
})
export class ProfilePreviewComponent {
  @Input() pictureUrl: string;
  @Input() name: string;
  @Input() position: string;
  @Input() displayBottomLine: boolean;
}
