import { Component, Input, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserProfile } from '../../models/user.model';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'ei-contact-form-part',
  templateUrl: './contact-form-part.component.html',
  styleUrls: ['./contact-form-part.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContactFormPartComponent implements OnInit, OnDestroy {
  prefilledProfile: boolean;

  @Input() form: FormGroup;

  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  constructor(private _fb: FormBuilder, private _userService: UserService) {}

  ngOnInit(): void {
    this.form.addControl('title', this._fb.control(null, Validators.required)),
      this.form.addControl('lastName', this._fb.control(null, Validators.required)),
      this.form.addControl('firstName', this._fb.control(null, Validators.required)),
      this.form.addControl('street', this._fb.control(null, Validators.required)),
      this.form.addControl('postalCode', this._fb.control(null, Validators.required)),
      this.form.addControl('place', this._fb.control(null, Validators.required)),
      this.form.addControl('email', this._fb.control(null, [Validators.required, Validators.email])),
      this.form.addControl('phone', this._fb.control(null, Validators.required));

    this._userService.userProfile$.pipe(takeUntil(this._unsubscribe$)).subscribe((profile: UserProfile): void => {
      this.form.patchValue(profile);

      this.form.get('title').disable();
      this.form.get('lastName').disable();
      this.form.get('firstName').disable();
      this.form.get('street').disable();
      this.form.get('postalCode').disable();
      this.form.get('place').disable();
      this.form.get('email').disable();
      this.form.get('phone').disable();

      this.prefilledProfile = true;
    });

    this._userService.getUserProfile();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
