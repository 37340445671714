import { Component, Input, OnInit, Optional, Self, ViewEncapsulation } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NgControl } from '@angular/forms';
import { DatePickerCustomHeaderComponent } from '../date-picker-custom-header/date-picker-custom-header.component';
import * as moment from 'moment';

@Component({
  selector: 'ei-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DatePickerComponent implements ControlValueAccessor, OnInit {
  minDate: moment.Moment;

  @Input() placeholder: string;
  @Input() type: string;
  @Input() required: boolean;
  @Input() optional: boolean;

  inputControl: AbstractControl;
  customHeader = DatePickerCustomHeaderComponent;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }

    this.minDate = moment();
  }

  ngOnInit(): void {
    this.inputControl = this.control.control;
  }

  onChangeFn = (_: any) => {};
  onTouchedFn = () => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(obj: any): void {}
}
