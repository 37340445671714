import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'ei-year-picker',
  templateUrl: './year-picker.component.html',
  styleUrls: ['./year-picker.component.scss'],
})
export class YearPickerComponent implements OnInit {
  options: string[] = [];
  inputControl: AbstractControl;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.inputControl = this.control.control;
    const startYear = new Date().getFullYear();
    const endYear = 1800;

    for (let i = startYear; i > endYear; i -= 1) {
      this.options.push(String(i));
    }
  }

  onChangeFn = (_: any) => {};
  onTouchedFn = () => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(obj: any): void {
    this.inputControl?.setValue(obj);
  }

  onChange(): void {
    this.onChangeFn(this.inputControl.value);
  }
}
