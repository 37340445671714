import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ReferencePreview } from '../../models/reference.model';

@Component({
  selector: 'ei-reference-list',
  templateUrl: './reference-list.component.html',
  styleUrls: ['./reference-list.component.scss'],
})
export class ReferenceListComponent {
  @Input() displayMoreSection: boolean;
  @Input() customClass: string;
  @Input() lang: string | undefined;

  reference: ReferencePreview[] = [
    {
      name: 'Esplanade Residence',
      type: $localize`apartment`,
      pictureUrl: 'assets/images/esplanade_residence.jpg',
    },
    {
      name: 'Lichtenrader Straße',
      type: $localize`apartment building`,
      pictureUrl: 'assets/images/lichtenraderstrasse.jpg',
    },
    {
      name: 'Am Hirschsprung',
      type: $localize`building`,
      pictureUrl: 'assets/images/am_hirschsprung.jpg',
    },
    {
      name: 'Blankenburgerstraße',
      type: $localize`building`,
      pictureUrl: 'assets/images/blankenburgerstr.jpg',
    },
    {
      name: 'Borgmannstraße',
      type: $localize`apartment building`,
      pictureUrl: 'assets/images/borgmannstr.jpg',
    },
    {
      name: 'Alt Moabit',
      type: $localize`apartment building`,
      pictureUrl: 'assets/images/alt_moabit_52_01.jpg',
    },
    {
      name: 'Bussardsteig',
      type: $localize`residence`,
      pictureUrl: 'assets/images/bussardsteig.jpg',
    },
    {
      name: 'Brückenstraße',
      type: $localize`apartment building`,
      pictureUrl: 'assets/images/brueckenstr.jpg',
    },
    {
      name: 'Hasenheide',
      type: $localize`apartment building`,
      pictureUrl: 'assets/images/hasenheide.jpg',
    },
    {
      name: 'Klixstraße',
      type: $localize`apartment building`,
      pictureUrl: 'assets/images/klixstr.jpg',
    },
  ];

  constructor(private _router: Router) {}

  onMoreClick = (): void => {
    this._router.navigateByUrl(this.lang ? `/${this.lang}/reference` : '/reference');
  };
}
