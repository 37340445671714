<div id="ei-contact-form">
  <div class="contact-form-wrapper">
    <div class="heading">
      <div i18n>Do you have any questions?</div>
      <div i18n>We will be happy to help you.</div>
    </div>
    <ei-profile-preview
      [name]="contactPerson?.name"
      [position]="contactPerson?.position"
      [pictureUrl]="contactPerson?.pictureUrl"
      [displayBottomLine]="true"
    ></ei-profile-preview>
    <div class="contact">
      <div class="phone">
        <mat-icon>phone</mat-icon>
        <a href="tel:+0308872330">Tel. 030 / 88 72 33 - 0</a>
      </div>
      <div class="email">
        <mat-icon>alternate_email</mat-icon>
        <a href="mailto:info@engelhardt-kg.com">info@engelhardt-kg.com</a>
      </div>
    </div>

    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="form-grid">
        <ei-input formControlName="firstName" class="first-name" placeholder="First name" i18n-placeholder></ei-input>
        <ei-input formControlName="lastName" class="last-name" placeholder="Last name" i18n-placeholder></ei-input>
        <ei-input
          formControlName="street"
          class="street"
          placeholder="Street / Housenumber"
          i18n-placeholder
        ></ei-input>
        <ei-input formControlName="postalcode" class="postalcode" placeholder="Postalcode" i18n-placeholder></ei-input>
        <ei-input formControlName="place" class="place" placeholder="Place" i18n-placeholder></ei-input>
        <ei-input formControlName="email" class="email" placeholder="Email" i18n-placeholder></ei-input>
        <ei-input formControlName="phone" class="phone" placeholder="Phone" i18n-placeholder></ei-input>
      </div>

      <ei-textarea formControlName="message" placeholder="Message" i18n-placeholder></ei-textarea>
      <ei-checkbox formControlName="gdprCompliant">
        <div class="regulation">
          <p i18n>
            I consent to the processing of my data as described in
            <a routerLink="/data-protection">the privacy policy.</a>
          </p>
        </div>
      </ei-checkbox>
      <div class="submission-status">
        <ei-form-submission-status
          headline="Message sent"
          subHeadline="We will contact you as soon as possible"
          [status]="status"
          i18n-headline
          i18n-subHeadline
        ></ei-form-submission-status>
      </div>
      <div class="button-container">
        <ei-button class="send-button" color="secondaryGreen" i18n>Send</ei-button>
      </div>
    </form>
  </div>
</div>
