import { Component, Input } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'ei-form-field-error',
  templateUrl: './form-field-error.component.html',
  styleUrls: ['./form-field-error.component.scss'],
})
export class FormFieldErrorComponent {
  @Input() control: FormControl;

  get showError(): boolean {
    if (!this.control) {
      return false;
    }

    const { dirty, touched } = this.control;

    return this.invalid ? dirty || touched : false;
  }

  getErrorMessage(): string {
    const validationErrors = this.control.errors as ValidationErrors;
    const error = Object.keys(validationErrors)[0];

    switch (error) {
      case 'email':
        return $localize`Invalid e-mail format.`;
      case 'passwordDiffers':
        return $localize`Passwords have to be the same.`;
      case 'fromNumberLargerThanTo':
        return $localize`'From' value must be lower or equal to 'to'.`;
      case 'passwordStrength':
        return $localize`Password has to be at least 8 characters long and contain one of each: capital letter, lower case letter,
          number, special character.`;
      case 'emailTaken':
        return $localize`This e-mail address in already in use. Please use a different one.`;
      case 'mustBeANumber':
        return $localize`Must be a number.`;
      case 'required':
      default:
        return $localize`This field is required.`;
    }
  }

  private get invalid(): boolean {
    return this.control ? this.control.invalid : false;
  }
}
