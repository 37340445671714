import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NavigationComponent } from './components/navigation/navigation.component';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './components/footer/footer.component';
import { SharedModule } from '../shared/shared.module';
import { MatDialogModule } from '@angular/material/dialog';
import { UserMenuComponent } from './components/user-menu/user-menu.component';
import { CollapseModule } from 'ngx-bootstrap/collapse';

@NgModule({
  declarations: [NavigationComponent, FooterComponent, UserMenuComponent],
  imports: [CommonModule, MatIconModule, RouterModule, SharedModule, MatDialogModule, CollapseModule.forRoot()],
  exports: [NavigationComponent, FooterComponent],
})
export class CoreModule {}
