import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ei-markdown',
  templateUrl: './markdown.component.html',
  styleUrls: ['./markdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class MarkdownComponent {
  @Input() content: string;
}
