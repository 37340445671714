import { Injectable } from '@angular/core';
import { SortCriteria } from '../models/sort-criteria';

@Injectable({
  providedIn: 'root',
})
export class SortCriteriaService {
  constructor() {}

  getSortCriteriaList = (): SortCriteria[] => {
    return [
      SortCriteria.TITLE_ASC,
      SortCriteria.TITLE_DESC,
      SortCriteria.PRICE_ASC,
      SortCriteria.PRICE_DESC,
      SortCriteria.DATE_ASC,
      SortCriteria.DATE_DESC,
    ];
  };
}
