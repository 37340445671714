<div id="ei-contact-form-part">
  <div *ngIf="prefilledProfile" class="info">
    <mat-icon>info</mat-icon>
    <span i18n>Contact details was taken from user profile</span>
  </div>
  <form [formGroup]="form">
    <ei-input class="salutation" formControlName="title" placeholder="Salutation" i18n-placeholder></ei-input>
    <ei-input class="first-name" formControlName="firstName" placeholder="First Name" i18n-placeholder></ei-input>
    <ei-input class="last-name" formControlName="lastName" placeholder="Last Name" i18n-placeholder></ei-input>
    <ei-input class="street" formControlName="street" placeholder="Street" i18n-placeholder></ei-input>
    <div class="wrapper">
      <ei-input
        class="wrapper-postcode"
        formControlName="postalCode"
        placeholder="Postcode"
        i18n-placeholder
      ></ei-input>
      <ei-input class="wrapper-place" formControlName="place" placeholder="Place" i18n-placeholder></ei-input>
    </div>
    <ei-input class="postcode" formControlName="postalCode" placeholder="Postcode" i18n-placeholder></ei-input>
    <ei-input class="place" formControlName="place" placeholder="Place" i18n-placeholder></ei-input>
    <ei-input class="email" formControlName="email" placeholder="E-Mail" i18n-placeholder></ei-input>
    <ei-input class="phone" formControlName="phone" placeholder="Phone" i18n-placeholder></ei-input>
  </form>
</div>
