<div id="ei-cookie-policy-dialog">
  <div class="top-container">
    <span class="headline" i18n>Cookie settings</span>
    <button type="submit" class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <div class="text" i18n>
    We use cookies to guarantee you the best possible user experience on our website. If you continue without changing
    your settings, we will assume that you agree to receive all cookies on this website.
  </div>
  <div class="buttons">
    <ei-button (buttonClick)="accept()" color="primary" i18n>Accept cookies</ei-button>
  </div>
</div>
