import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Role } from '../models/role.model';
import { SnackbarService } from '../services/snackbar.service';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root',
})
export class SellerRoleGuard implements CanActivate {
  constructor(private _userService: UserService, private _router: Router, private _snackbarService: SnackbarService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this._userService.user$.pipe(
      map(() => {
        const hasSellerRole = this._userService.hasRole(Role.SELLER);

        if (!hasSellerRole) {
          this._snackbarService.error($localize`Request seller account type for access.`);
        }

        return hasSellerRole || this._router.createUrlTree(['/']);
      }),
    );
  }
}
