import { Component, HostListener, OnInit } from '@angular/core';
import { UtilitiesService } from './shared/services/utilities.service';
import { CookiePolicyDialogComponent } from './shared/components/cookie-policy-dialog/cookie-policy-dialog.component';
import { DialogService } from './shared/services/dialog.service';
import { delay, filter } from 'rxjs/operators';
import { Event, Router, Scroll } from '@angular/router';
import { ViewportScroller } from '@angular/common';

@Component({
  selector: 'ei-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  title = 'engelhardt-frontend';

  constructor(
    private _utilitiesService: UtilitiesService,
    private _dialogService: DialogService,
    private router: Router,
    private viewportScroller: ViewportScroller,
  ) {}

  @HostListener('document:click', ['$event'])
  documentClick = (event: MouseEvent): void => {
    this._utilitiesService.documentClickedTarget$.next(event.target as HTMLElement);
  };

  ngOnInit(): void {
    if (!JSON.parse(localStorage.getItem('cookie-accepted'))) {
      this._dialogService.open(CookiePolicyDialogComponent, { panelClass: 'cookie-dialog' });
    }

    this.router.events
      .pipe(filter((e: Event): e is Scroll => e instanceof Scroll))
      .pipe(delay(1))
      .subscribe((e) => {
        if (e.position) {
          // backward navigation
          this.viewportScroller.scrollToPosition(e.position);
        } else if (e.anchor) {
          // anchor navigation
          this.viewportScroller.scrollToAnchor(e.anchor);
        } else {
          // forward navigation
          this.viewportScroller.scrollToPosition([0, 0]);
        }
      });
  }
}
