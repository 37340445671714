<div id="ei-file-uploader">
  <label for="file">
    <div class="details" [class]="state">
      <ng-container [ngSwitch]="state">
        <ng-container *ngSwitchCase="UploaderState.BadFormat">
          <mat-icon class="details-icon">warning</mat-icon>
          <p i18n>File format not supported.</p>
        </ng-container>
        <ng-container *ngSwitchCase="UploaderState.DragOver">
          <mat-icon class="details-icon">cloud_upload</mat-icon>
          <p i18n>Drop file to begin upload.</p>
        </ng-container>
        <ng-container *ngSwitchCase="UploaderState.Loaded">
          <mat-icon class="details-icon">done</mat-icon>
          <p i18n>File upload complete!</p>
        </ng-container>
        <ng-container *ngSwitchCase="UploaderState.IsUploading">
          <mat-spinner [diameter]="32"></mat-spinner>
          <p i18n>Uploading file...</p>
        </ng-container>
        <ng-container *ngSwitchDefault>
          <mat-icon svgIcon="upload" class="details-icon"></mat-icon>
          <p i18n>Drag-and-drop a file, or <span class="browse-file">browse to upload</span></p>
        </ng-container>
      </ng-container>
      <button
        *ngIf="state === UploaderState.IsUploading"
        mat-icon-button
        class="cancel-button"
        (click)="onCancel($event)"
      >
        <mat-icon>cancel</mat-icon>
      </button>
    </div>
  </label>

  <input type="file" id="file" #fileUploader [accept]="acceptType" (input)="onFileChange($event.target.files)" />
</div>
