<div id="ei-year-picker">
  <select [formControl]="inputControl">
    <option [value]="null"></option>
    <option *ngFor="let option of options" [value]="option">
      {{ option }}
    </option>
  </select>

  <ei-form-field-error [control]="inputControl"></ei-form-field-error>
</div>
