import { Injectable } from '@angular/core';
import { Category } from '../models/category.model';

@Injectable({
  providedIn: 'root',
})
export class CategoryService {
  constructor() {}

  getCategoryList = (): Category[] => {
    return [
      Category.APARTMENT_BUILDINGS,
      Category.COMMERCIAL_BUILDINGS,
      Category.LAND,
      Category.SINGLE_FAMILY_BUILDINGS,
      Category.CONDOMINIUMS,
    ];
  };

  translateCategoryName = (category: Category): string => {
    switch (category) {
      case Category.APARTMENT_BUILDINGS:
        return $localize`Apartment buildings`;
      case Category.COMMERCIAL_BUILDINGS:
        return $localize`Commercial Buildings`;
      case Category.LAND:
        return $localize`Land`;
      case Category.SINGLE_FAMILY_BUILDINGS:
        return $localize`Single family buildings`;
      case Category.CONDOMINIUMS:
        return $localize`Condominiums`;
    }
  };
}
