<div id="ei-textarea">
  <label *ngIf="placeholder" class="placeholder" [class.required]="required" [class.optional]="optional">{{
    placeholder
  }}</label>

  <mat-form-field appearance="outline">
    <textarea
      matInput
      [formControl]="inputControl"
      [cdkTextareaAutosize]="true"
      [cdkAutosizeMinRows]="minRows"
    ></textarea>
  </mat-form-field>

  <ei-form-field-error [control]="inputControl"></ei-form-field-error>
</div>
