import { Injectable } from '@angular/core';
import { ClientApiService } from 'src/app/core/services/api/client-api.service';

@Injectable({
  providedIn: 'root',
})
export class ContactFormService {
  constructor(private _clientApiService: ClientApiService) {}

  sendContactDetails = (payload) => this._clientApiService.post('contact/sale', payload);
}
