<div id="ei-login">
  <div class="button-container">
    <button type="submit" class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ei-heading header="Log in" i18n-header></ei-heading>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <ei-input formControlName="login" placeholder="Login" type="email" [optional]="true" i18n-placeholder></ei-input>
    <ei-input
      formControlName="password"
      placeholder="Password"
      type="password"
      [optional]="true"
      i18n-placeholder
    ></ei-input>
    <ei-button color="primary" [disabled]="form.invalid" [isLoading]="isLoading" i18n>Login</ei-button>
  </form>
  <span class="error-message" *ngIf="isError" i18n>Authentication failed, try again.</span>
  <div (click)="onForgotPassword()" class="forgot-password" i18n>Forgot password?</div>
</div>
