import { Injectable } from '@angular/core';
import { Router, CanActivate, UrlTree } from '@angular/router';
import { LoginService } from 'src/app/modules/home/services/login.service';
import { SnackbarService } from '../services/snackbar.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(
    private _loginService: LoginService,
    private _router: Router,
    private _snackbarService: SnackbarService,
  ) {}

  canActivate(): boolean | UrlTree {
    if (!this._loginService.isLoggedIn()) {
      this._snackbarService.error($localize`Login to access more details.`);
      return this._router.createUrlTree(['/']);
    }

    return true;
  }
}
