import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconsService {
  basePath: string;
  icons: Map<string, string>;

  constructor(private _matIconRegistry: MatIconRegistry, private _domSanitizer: DomSanitizer) {
    this.basePath = 'assets/images/icons';

    this.icons = new Map<string, string>();
    this.icons.set('alert', this._setImage('general/Alert.svg'));
    this.icons.set('alert-art', this._setImage('general/Alert-Art.svg'));
    this.icons.set('arrow-down', this._setImage('general/Arrow-Down.svg'));
    this.icons.set('arrow-left', this._setImage('general/Arrow-Left.svg'));
    this.icons.set('arrow-right', this._setImage('general/Arrow-Right.svg'));
    this.icons.set('arrow-up', this._setImage('general/Arrow-Up.svg'));
    this.icons.set('building', this._setImage('general/Building.svg'));
    this.icons.set('Calendar', this._setImage('general/Calendar.svg'));
    this.icons.set('caret-down', this._setImage('general/Caret-Down.svg'));
    this.icons.set('caret-left', this._setImage('general/Caret-Left.svg'));
    this.icons.set('caret-right', this._setImage('general/Caret-Right.svg'));
    this.icons.set('caret-up', this._setImage('general/Caret-Up.svg'));
    this.icons.set('caret-off', this._setImage('general/Caret-Off.svg'));
    this.icons.set('caret-on', this._setImage('general/Caret-On.svg'));
    this.icons.set('close', this._setImage('general/Close.svg'));
    this.icons.set('delete', this._setImage('general/Delete.svg'));
    this.icons.set('download', this._setImage('general/Download.svg'));
    this.icons.set('edit', this._setImage('general/Edit.svg'));
    this.icons.set('email', this._setImage('general/Email.svg'));
    this.icons.set('external', this._setImage('general/External.svg'));
    this.icons.set('facebook', this._setImage('general/Facebook.svg'));
    this.icons.set('fax', this._setImage('general/Fax.svg'));
    this.icons.set('filetype', this._setImage('general/Filetype.svg'));
    this.icons.set('flag-de', this._setImage('general/Flag-DE.svg'));
    this.icons.set('flag-gb', this._setImage('general/Flag-GB.svg'));
    this.icons.set('globe', this._setImage('general/Globe.svg'));
    this.icons.set('info', this._setImage('general/Info.svg'));
    this.icons.set('instagram', this._setImage('general/Instagram.svg'));
    this.icons.set('layers', this._setImage('general/Layers.svg'));
    this.icons.set('linkedin', this._setImage('general/Linkedin.svg'));
    this.icons.set('location', this._setImage('general/Location.svg'));
    this.icons.set('menu', this._setImage('general/Menu.svg'));
    this.icons.set('minus-alt', this._setImage('general/Minus-Alt.svg'));
    this.icons.set('overflow', this._setImage('general/Overflow.svg'));
    this.icons.set('phone', this._setImage('general/Phone.svg'));
    this.icons.set('plus-alt', this._setImage('general/Plus-Alt.svg'));
    this.icons.set('radio-off', this._setImage('general/Radio-Off.svg'));
    this.icons.set('radio-on', this._setImage('general/Radio-On.svg'));
    this.icons.set('remove', this._setImage('general/Remove.svg'));
    this.icons.set('remove-alt', this._setImage('general/Remove-Alt.svg'));
    this.icons.set('search', this._setImage('general/Search.svg'));
    this.icons.set('shape-dot', this._setImage('general/Shape-Dot.svg'));
    this.icons.set('success', this._setImage('general/Success.svg'));
    this.icons.set('twitter', this._setImage('general/Twitter.svg'));
    this.icons.set('upload', this._setImage('general/Upload.svg'));
  }

  private _setImage = (imagePath: string) => `${this.basePath}/${imagePath}`;

  initialize = (): void => {
    for (const key of this.icons.keys()) {
      this._matIconRegistry.addSvgIcon(key, this.byPassUrl(this.icons.get(key)));
    }
  };

  private byPassUrl = (link: string) => this._domSanitizer.bypassSecurityTrustResourceUrl(link);
}
