import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SnackbarService } from '../../../../shared/services/snackbar.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs/operators';
import { UserService } from '../../../../shared/services/user.service';

@Component({
  selector: 'ei-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForgotPasswordComponent implements OnInit {
  isLoading: boolean;
  form: FormGroup;

  constructor(
    private _dialogRef: MatDialogRef<ForgotPasswordComponent>,
    private _snackbarService: SnackbarService,
    private _userService: UserService,
    private _fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      email: this._fb.control(null, [Validators.required, Validators.email]),
    });
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  submit(): void {
    if (this.form.valid) {
      this.isLoading = true;

      this._userService
        .resetPassword(this.form.value)
        .pipe(
          finalize(() => {
            this.isLoading = false;
            this._snackbarService.success($localize`Your password has been reset. Please check your email.`);
            this._dialogRef.close();
          }),
        )
        .subscribe();
    }
  }
}
