import { Component, Input } from '@angular/core';
import { NewsPreview } from '../../models/news.model';
import { Router } from '@angular/router';

@Component({
  selector: 'ei-news-preview',
  templateUrl: './news-preview.component.html',
  styleUrls: ['./news-preview.component.scss'],
})
export class NewsPreviewComponent {
  @Input() news: NewsPreview;
  @Input() showMoreButtonOnDesktop: boolean;

  constructor(private _router: Router) {}

  onMoreClick = (): void => {
    this._router.navigateByUrl('/news');
  };

  getNewsLink = (): string => `/news/${this.news.id}`;
}
