import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { Category } from '../../models/category.model';
import { CategoryService } from '../../services/category.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ei-category-selector',
  templateUrl: './category-selector.component.html',
  styleUrls: ['./category-selector.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CategorySelectorComponent {
  private _selectedCategory: Category;

  categories: Category[];

  @Output() categorySelect: EventEmitter<Category> = new EventEmitter<Category>();

  constructor(private _categoryService: CategoryService, private _route: ActivatedRoute) {
    this.categories = this._categoryService.getCategoryList();

    const routeCategory = this._route.snapshot.paramMap.get('category');

    if (routeCategory) {
      this._selectedCategory = Category[routeCategory.toUpperCase()];
    }
  }

  isActive = (category: Category): boolean =>
    !category && !this._selectedCategory ? true : this._selectedCategory === category;

  onCategorySelect = (event: Category): void => {
    this._selectedCategory = this.categories.find((category: Category): boolean => category === event);
    this.categorySelect.emit(this._selectedCategory);
  };
}
