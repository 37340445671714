import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ClientApiService } from 'src/app/core/services/api/client-api.service';
import { Credentials } from '../models/auth.model';
import jwtDecode from 'jwt-decode';
import { AuthService } from './auth.service';
import { LocalStorageService } from './local-storage-service';
import { UserService } from 'src/app/shared/services/user.service';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(
    private _clientApiService: ClientApiService,
    private _authService: AuthService,
    private _localStorageService: LocalStorageService,
    private _userService: UserService,
    private _router: Router,
  ) {}

  login(payload: Credentials): Observable<any> {
    return this._clientApiService.post('auth/token', payload).pipe(
      tap((response: any): void => {
        const token = response.token;
        const expiry = (jwtDecode(response.token) as any).exp;

        this._localStorageService.setToken(token);
        this._localStorageService.setExp(expiry);
        this._authService.setAuthData(token, expiry);
        this._userService.getUser().subscribe();
      }),
    );
  }

  isLoggedIn(): boolean {
    return this._authService.isTokenValid();
  }

  logout(): void {
    this._authService.token = null;
    this._authService.exp = null;
    this._userService.user$.next(null);
    this._userService.userProfile$.next(null);
    this._localStorageService.removeExp();
    this._localStorageService.removeToken();
    this._router.navigateByUrl('/');
  }
}
