<div class="ei-checkbox" [class.disabled]="inputControl.disabled">
  <label>
    <div class="checkbox">
      <mat-icon class="done-icon">done</mat-icon>
      <input type="checkbox" [formControl]="inputControl" (change)="onCheck($event)" [class.circle]="circle" />
    </div>
    <span>
      <ng-content></ng-content>
    </span>
  </label>

  <ei-form-field-error [control]="inputControl"></ei-form-field-error>
</div>
