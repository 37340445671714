import { Component, OnInit } from '@angular/core';
import { User } from '../../../shared/models/user.model';
import { UserService } from '../../../shared/services/user.service';
import { LoginService } from '../../../modules/home/services/login.service';
import { Router } from '@angular/router';
import { Role } from '../../../shared/models/role.model';

@Component({
  selector: 'ei-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  user: User;

  constructor(private _userService: UserService, private _loginService: LoginService, private _router: Router) {}

  ngOnInit(): void {
    this._userService.user$.subscribe((user: User): void => {
      this.user = user;
    });
  }

  hasSellerRole = () => this._userService.hasRole(Role.SELLER);

  redirect(): void | Promise<boolean> {
    return this._router.navigateByUrl(`/my-objects`);
  }

  logout = (): void => {
    this._loginService.logout();
  };
}
