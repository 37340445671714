import { Injectable } from '@angular/core';
import { finalize } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { LocalStorageService } from './local-storage-service';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  token: string;
  exp: number;

  constructor(private _userService: UserService, private _localStorageService: LocalStorageService) {}

  authenticateOnPageLoad(): Promise<void> {
    return new Promise<void>((resolve, _) => {
      try {
        const token = this._localStorageService.getToken();
        const exp = this._localStorageService.getExp();

        this.setAuthData(token, exp);

        if (token) {
          return this._userService
            .getUser()
            .pipe(
              finalize((): void => {
                resolve();
              }),
            )
            .subscribe();
        }

        resolve();
      } catch {
        resolve();
      }
    });
  }

  setAuthData(token: string, exp: number): void {
    this.token = token;
    this.exp = exp;
  }

  isTokenValid(): boolean {
    return !!this.token && new Date(Date.now() + this.exp) > new Date();
  }
}
