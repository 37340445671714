<div id="ei-date-picker-custom-header">
  <div>
    <span class="month">{{ monthLabel }}</span>
    <span class="year">{{ yearLabel }}</span>
  </div>
  <div>
    <button mat-icon-button (click)="previousClicked()">
      <mat-icon>arrow_left</mat-icon>
    </button>
    <button mat-icon-button class="example-double-arrow" (click)="nextClicked()">
      <mat-icon>arrow_right</mat-icon>
    </button>
  </div>
</div>
