import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../components/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialog } from '../models/confirmation-dialog.model';

@Injectable({
  providedIn: 'root',
})
export class DialogService {
  private readonly _defaultConfig: MatDialogConfig;

  constructor(private _dialog: MatDialog) {
    this._defaultConfig = {
      panelClass: 'ei-dialog',
      maxWidth: '100vh',
      autoFocus: false,
    };
  }

  open(component: ComponentType<any>, config?: MatDialogConfig): MatDialogRef<any> {
    return this._dialog.open(component, { ...this._defaultConfig, ...config });
  }

  openConfirmationDialog = (data: ConfirmationDialog): MatDialogRef<ConfirmationDialogComponent> => {
    return this._dialog.open(ConfirmationDialogComponent, { data });
  };

  closeAll = (): void => {
    this._dialog.closeAll();
  };
}
