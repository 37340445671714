import { Component, Input, Output, EventEmitter, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ei-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent {
  @Input() color:
    | 'primary'
    | 'secondaryGreen'
    | 'secondaryWhite'
    | 'secondaryRed'
    | 'secondaryBlack'
    | 'text'
    | 'text-green'
    | 'navButton' = 'secondaryGreen';
  @Input() small: boolean;
  @Input() large: boolean;
  @Input() disabled: boolean;
  @Input() isLoading: boolean;
  @Input() icon: string;
  @Input() noMinWidth: boolean;

  @Output() buttonClick: EventEmitter<Event> = new EventEmitter<Event>();

  toDashedCase = (color: string): string => {
    if (color) {
      return color.replace(/[A-Z]/g, (m: string): string => `-${m.toLowerCase()}`);
    }
  };

  onClick = (event: Event): void => {
    this.buttonClick.emit(event);
  };
}
