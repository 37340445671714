import { Component, ElementRef, Input, OnDestroy, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { UtilitiesService } from '../../services/utilities.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ei-multi-select-filter',
  templateUrl: './multi-select-filter.component.html',
  styleUrls: ['./multi-select-filter.component.scss'],
})
export class MultiSelectFilterComponent implements OnInit, ControlValueAccessor, OnDestroy {
  private _unsubscribe$ = new Subject();
  private _options: string[];

  selectedOptions: string[];
  inputControl = new FormControl('');
  open: boolean;

  @Input() placeholder: string;
  @Input() set options(value: string[]) {
    this.selectedOptions = this.selectedOptions.filter((el) => value.includes(el));
    this.onChangeFn(this.selectedOptions);
    this._options = value;
  }
  get options(): string[] {
    return this._options;
  }

  @ViewChild('multiSelectFilter', { read: ElementRef, static: false }) multiSelectFilter: ElementRef;

  constructor(@Self() @Optional() public control: NgControl, private _utilitiesService: UtilitiesService) {
    this._options = [];
    this.selectedOptions = [];

    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this._utilitiesService.documentClickedTarget$
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe((target: HTMLElement): void => {
        if (!this.multiSelectFilter.nativeElement.contains(target)) {
          this.onClickedOutside();
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  limitIfNotInCurrentOptions(options: string[]): string[] {
    return options?.filter((selectedOption: string) => this.options.includes(selectedOption));
  }

  onSelect(item: string): void {
    this.selectedOptions.push(item);
    this.onChangeFn(this.selectedOptions);
    this.clearInput();
  }

  removeItem(value: string): void {
    this.selectedOptions = this.selectedOptions.filter((el: string) => el !== String(value));
    this.onChangeFn(this.selectedOptions);
  }

  onChangeFn = (_: any) => {};

  onTouchedFn = () => {};

  writeValue(obj: any): void {
    if (!obj) {
      this.selectedOptions = [];
    } else {
      this.selectedOptions = [obj];
    }
  }

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  openPanel = (): void => {
    this.open = true;
  };

  translate = (item: string) => {
    switch (item) {
      case 'APARTMENT_BUILDINGS':
        return $localize`Apartment buildings`;
      case 'COMMERCIAL_BUILDINGS':
        return $localize`Commercial Buildings`;
      case 'LAND':
        return $localize`Land`;
      case 'SINGLE_FAMILY_BUILDINGS':
        return $localize`Single family buildings`;
      case 'CONDOMINIUMS':
        return $localize`Condominiums`;
      default:
        return item;
    }
  };

  filteredOptions(options: string[]): string[] {
    const optionsWithoutSelected = options?.filter((el: string) => {
      return !this.selectedOptions?.includes(el);
    });

    return optionsWithoutSelected.filter((el: string) => {
      return el.replace('_', ' ').toLowerCase().startsWith(String(this.inputControl.value).toLowerCase());
    });
  }

  clearInput(): void {
    this.inputControl.setValue('');
  }

  onClickedOutside(): void {
    this.open = false;
    this.clearInput();
  }
}
