<div id="ei-multi-select-filter" #multiSelectFilter>
  <div class="input">
    <label class="placeholder">{{ placeholder }}</label>
    <div class="search" (click)="openPanel()" [ngClass]="{ 'search--active': open }">
      <input placeholder="Select" [formControl]="inputControl" i18n-placeholder />
      <mat-icon>{{ open ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
    </div>
  </div>
  <div class="dropdown" [class.show]="open">
    <li *ngFor="let item of filteredOptions(options)">
      <button (click)="onSelect(item)">
        <span>{{ translate(item) }}</span>
      </button>
    </li>
    <li class="no-option" *ngIf="!filteredOptions(options).length">
      <span i18n>No options found.</span>
    </li>
  </div>
  <div class="selected-items">
    <li *ngFor="let selectedOption of limitIfNotInCurrentOptions(selectedOptions)">
      <span>{{ translate(selectedOption) }}</span>
      <button mat-icon-button (click)="removeItem(selectedOption)">
        <mat-icon>close</mat-icon>
      </button>
    </li>
  </div>
</div>
