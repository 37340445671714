import { Component, Input } from '@angular/core';
import { OfferPreview } from '../../models/offer.model';

@Component({
  selector: 'ei-offer-list',
  templateUrl: './offer-list.component.html',
  styleUrls: ['./offer-list.component.scss'],
})
export class OfferListComponent {
  @Input() offers: OfferPreview[];
}
