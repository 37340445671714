export interface SnackbarData {
  message?: string;
  type?: SnackbarType;
}

export enum SnackbarType {
  SUCCESS = 'done',
  WARN = 'warning',
  ERROR = 'error',
}
