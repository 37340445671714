<div id="ei-button" [class.small]="small" [class.large]="large">
  <button
    [class]="toDashedCase(color)"
    (click)="onClick($event)"
    [disabled]="disabled || isLoading"
    [class.has-icon]="icon"
    [class.no-min-width]="noMinWidth"
  >
    <ng-container *ngIf="isLoading; else elseTemplate">
      <mat-spinner diameter="20"></mat-spinner>
    </ng-container>
    <ng-template #elseTemplate>
      <span class="button-text">
        <ng-content></ng-content>
      </span>
      <mat-icon *ngIf="icon">{{ icon }}</mat-icon>
    </ng-template>
  </button>
</div>
