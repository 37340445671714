<div id="ei-footer">
  <div class="content">
    <div class="content-item" id="buy-sell">
      <div [class.collapsed]="collapsed['buy']" class="header">
        <span i18n>Buy</span>
        <mat-icon (click)="toggleCollapse('buy')">{{ getCollapseIcon('buy') }}</mat-icon>
      </div>
      <div [collapse]="collapsed['buy']" [isAnimated]="true" class="collapse-content">
        <p class="description upper">
          <a [routerLink]="getRouterLink('/buy/commercial_buildings')" i18n>Commercial buildings</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/buy/apartment_buildings')" i18n>Apartment buildings</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/buy/single_family_buildings')" i18n>Single family buildings</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/buy/condominiums')" i18n>Condominiums</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/buy/land')" i18n>Land</a>
        </p>
      </div>
    </div>
    <div class="content-item" id="corporate">
      <div [class.collapsed]="collapsed['corporate']" class="header">
        <span i18n>Corporate</span>
        <mat-icon (click)="toggleCollapse('corporate')">{{ getCollapseIcon('corporate') }}</mat-icon>
      </div>
      <div [collapse]="collapsed['corporate']" [isAnimated]="true" class="collapse-content">
        <p class="description upper">
          <a [routerLink]="getRouterLink('/about-us')" i18n>About us</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/team')" i18n>Team</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/reference')" i18n>Reference</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/sponsoring')" i18n>Sponsoring</a>
        </p>
        <p class="description upper">
          <a [routerLink]="getRouterLink('/career')" i18n>Career</a>
        </p>
      </div>
    </div>
    <div class="content-item" id="legal">
      <div [class.collapsed]="collapsed['legal']" class="header">
        <span i18n>Legal</span>
        <mat-icon (click)="toggleCollapse('legal')">{{ getCollapseIcon('legal') }}</mat-icon>
      </div>
      <div [collapse]="collapsed['legal']" [isAnimated]="true" class="collapse-content">
        <p class="description">
          <a [routerLink]="getRouterLink('/imprint')" i18n>Imprint</a>
        </p>
        <p class="description">
          <a [routerLink]="getRouterLink('/data-protection')" i18n>Data Protection</a>
        </p>
        <p class="description">
          <a
            href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
            target="_blank"
            i18n
            >EU online dispute resolution platform</a
          >
        </p>
        <p class="description">
          <a href="https://www.verbraucher-schlichter.de" target="_blank" i18n>General consumer arbitration board</a>
        </p>
      </div>
    </div>
    <div class="content-item" id="contact">
      <div [class.collapsed]="collapsed['contact']" class="header">
        <span i18n>Contact</span>
        <mat-icon (click)="toggleCollapse('contact')">{{ getCollapseIcon('contact') }}</mat-icon>
      </div>
      <div [collapse]="collapsed['contact']" [isAnimated]="true" class="collapse-content">
        <p class="description">Lienz Liegenschaften GmbH & Co. Engelhardt Immobilien KG</p>
        <p class="description">Katharinenstr. 26, 10711 Berlin</p>
        <p class="description">
          <a href="tel:+0308872330">Tel. 030 / 88 72 33 - 0</a>
        </p>
        <p class="description">
          <a href="tel:+03088723334">Fax. 030 / 88 72 33 - 34</a>
        </p>
        <p class="description">
          <a href="mailto:info@engelhardt-kg.com">info@engelhardt-kg.com</a>
        </p>
        <p class="description">
          <a href="http://www.engelhardt-kg.com" target="_blank">www.engelhardt-kg.com</a>
        </p>
        <p class="description">
          <a routerLink="/">
            <img src="assets/images/logo.svg" alt="Engelhardt Immobilien" />
          </a>
        </p>
      </div>
    </div>
  </div>
  <div class="icons">
    <a href="https://www.facebook.com/EngelhardtImmobilienKG/" target="blank">
      <div class="icon-circle">
        <img src="assets/images/icons/brands/facebook.svg" alt="Facebook" />
      </div>
    </a>
    <a href="https://www.instagram.com/engelhardt_immobilien_kg/" target="blank">
      <div class="icon-circle">
        <img src="assets/images/icons/brands/instagram.svg" alt="Instagram" />
      </div>
    </a>
    <a href="https://www.linkedin.com/company/engelhardt-immobilien-kg" target="blank">
      <div class="icon-circle">
        <img src="assets/images/icons/brands/linkedin.svg" alt="Linkedin" />
      </div>
    </a>
    <a href="https://www.pinterest.de/engelhardtimmobilienberlin/" target="blank">
      <div class="icon-circle">
        <img src="assets/images/icons/brands/pinterest.svg" alt="Pinterest" />
      </div>
    </a>
    <a href="https://www.xing.com/companies/engelhardtimmobilienkg" target="blank">
      <div class="icon-circle">
        <img src="assets/images/icons/brands/xing.svg" alt="Xing" />
      </div>
    </a>
  </div>
  <div class="copyright-bar">© 1971 – {{ year }} Engelhardt Immobilien® KG</div>
</div>
