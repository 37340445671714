export interface ConfirmationDialog {
  title: string;
  message?: string;
  cancelText: string;
  confirmText: string;
  checkboxText?: string;
}

export enum ConfirmationAction {
  CONFIRM = 'CONFIRM',
  CANCEL = 'CANCEL',
}

export interface Confirmation {
  action: ConfirmationAction;
  checkbox?: boolean;
}
