<div id="ei-time-picker">
  <label class="placeholder" i18n>Time</label>

  <select [formControl]="inputControl">
    <option [value]="null"></option>
    <option *ngFor="let option of options" [value]="option">
      {{ option }}
    </option>
  </select>

  <ei-form-field-error [control]="inputControl"></ei-form-field-error>
</div>
