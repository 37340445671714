import { AbstractControl, ValidatorFn } from '@angular/forms';

export class CustomValidator {
  static passwordStrength = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const expression = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/;
      return expression.test(control.value) ? null : { passwordStrength: { value: control.value } };
    };
  };

  static isNumber = (): ValidatorFn => {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const expression = /^[0-9]\d*$/;
      return expression.test(control.value) ? null : { mustBeANumber: { value: control.value } };
    };
  };
}
