import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { SnackbarService } from '../../../shared/services/snackbar.service';
import { ErrorResponse } from '../../models/api.model';

@Injectable({
  providedIn: 'root',
})
export abstract class ApiService {
  constructor(private _httpClient: HttpClient, private _snackbarService: SnackbarService) {}

  protected abstract getUrl: (resource: string) => string;

  get(resource: string, query: any = {}): Observable<any> {
    return this._httpClient
      .get<any>(this.getUrl(resource), { params: this._parseParams(query) })
      .pipe(catchError(this._handleError));
  }

  post(resource: string, payload?: any): Observable<any> {
    return this._httpClient.post<any>(this.getUrl(resource), payload).pipe(catchError(this._handleError));
  }

  delete(resource: string): Observable<any> {
    return this._httpClient.delete<any>(this.getUrl(resource)).pipe(catchError(this._handleError));
  }

  put(resource: string, payload?): Observable<any> {
    return this._httpClient
      .put<any>(this.getUrl(resource), { ...payload })
      .pipe(catchError(this._handleError));
  }

  patch(resource: string, payload?): Observable<any> {
    return this._httpClient
      .patch<any>(this.getUrl(resource), { ...payload })
      .pipe(catchError(this._handleError));
  }

  private _handleError = (error: HttpErrorResponse): Observable<any> => {
    const message = (error.error as ErrorResponse)?.message;

    if (error.status >= 500) {
      this._snackbarService.error($localize`Something went wrong, please try again.`);
    }

    return throwError({
      message,
      status: error.status,
    });
  };

  private _parseParams = (params: any): any =>
    Object.keys(params).reduce(
      (acc: any, key: string): void => (params[key] === null ? acc : { ...acc, [key]: params[key] }),
      {},
    );
}
