import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from './core/core.module';
import { HttpClientModule, HttpClientJsonpModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';
import { AuthService } from './modules/home/services/auth.service';

import de from '@angular/common/locales/de';
import enGB from '@angular/common/locales/en-GB';
import { TokenInterceptor } from './shared/interceptors/token-interceptor';
import { UnauthorizedInterceptor } from './shared/interceptors/unauthorized-interceptor';
import { IconsService } from './shared/services/icons.service';

registerLocaleData(de);
registerLocaleData(enGB);

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    CoreModule,
    HttpClientModule,
    HttpClientJsonpModule,
    MatDialogModule,
  ],
  bootstrap: [AppComponent],
  providers: [
    AuthService,
    IconsService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (authService: AuthService) => () => authService.authenticateOnPageLoad(),
      deps: [AuthService],
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (iconsService: IconsService) => () => iconsService.initialize(),
      deps: [IconsService],
      multi: true,
    },
  ],
})
export class AppModule {}
