import { Component, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';
import { SelectOption } from '../../models/select-option.model';

@Component({
  selector: 'ei-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
})
export class SelectComponent implements OnInit {
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() optional: boolean;
  @Input() options: SelectOption[];

  inputControl: AbstractControl;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.inputControl = this.control.control;
  }

  onChangeFn = (_: any) => {};
  onTouchedFn = () => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(obj: any): void {
    this.inputControl?.setValue(obj);
  }

  onChange(): void {
    this.onChangeFn(this.inputControl.value);
  }
}
