import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ei-arrow-list',
  templateUrl: './arrow-list.component.html',
  styleUrls: ['./arrow-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ArrowListComponent {
  @Input() items: string[];
}
