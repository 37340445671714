import { Component, Input } from '@angular/core';
import { FormSubmissionStatus } from '../../models/form-submission-status.model';

@Component({
  selector: 'ei-form-submission-status',
  templateUrl: './form-submission-status.component.html',
  styleUrls: ['./form-submission-status.component.scss'],
})
export class FormSubmissionStatusComponent {
  @Input() status: FormSubmissionStatus;
  @Input() headline: string;
  @Input() subHeadline: string;

  FormSubmissionStatus = FormSubmissionStatus;
}
