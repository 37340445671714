import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ClientApiService extends ApiService {
  protected readonly apiUrl = environment.clientApiUrl;

  getUrl = (resource: string) => {
    return `${this.apiUrl}/${resource}`;
  };
}
