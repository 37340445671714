import { Component, EventEmitter, Output, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Category } from '../../models/category.model';
import { SortCriteria } from '../../models/sort-criteria';
import { SortCriteriaService } from '../../services/sort-criteria.service';

@Component({
  selector: 'ei-sort',
  templateUrl: './sort.component.html',
  styleUrls: ['./sort.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SortComponent {
  private _selectedSortCriteria: SortCriteria;

  sortCriteria: SortCriteria[];

  @Output() sortCriteriaSelect: EventEmitter<SortCriteria> = new EventEmitter<SortCriteria>();

  constructor(private _sortCriteriaService: SortCriteriaService, private _route: ActivatedRoute) {
    this.sortCriteria = this._sortCriteriaService.getSortCriteriaList();

    const routeCategory = this._route.snapshot.paramMap.get('category');

    if (routeCategory) {
      this._selectedSortCriteria = SortCriteria[routeCategory.toUpperCase()];
    }
  }

  isActive = (category: SortCriteria): boolean =>
    !category && !this._selectedSortCriteria ? true : this._selectedSortCriteria === category;

  onSortCriteriaSelect = (event: SortCriteria): void => {
    this._selectedSortCriteria = this.sortCriteria.find((category: SortCriteria): boolean => category === event);
    this.sortCriteriaSelect.emit(this._selectedSortCriteria);
  };
}
