<div class="ei-edit-button">
  <div class="desktop">
    <ei-button
      *ngIf="!editMode"
      [small]="true"
      [noMinWidth]="true"
      [disabled]="disabled"
      (buttonClick)="onClick()"
      i18n
    >
      Edit
    </ei-button>
    <ei-button *ngIf="editMode" [small]="true" [noMinWidth]="true" [disabled]="disabled" (buttonClick)="onClick()" i18n>
      Save
    </ei-button>
  </div>
</div>
