import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfirmationAction, ConfirmationDialog } from '../../models/confirmation-dialog.model';

@Component({
  selector: 'ei-confirmation-dialog',
  templateUrl: './confirmation-dialog.component.html',
  styleUrls: ['./confirmation-dialog.component.scss'],
})
export class ConfirmationDialogComponent implements OnInit {
  form: FormGroup;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: ConfirmationDialog,
    private _mdDialogRef: MatDialogRef<ConfirmationDialog>,
    private _fb: FormBuilder,
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      checkbox: this._fb.control(false),
    });
  }

  @HostListener('keydown.esc') onEsc = (): void => {
    this._close(ConfirmationAction.CANCEL);
  };

  cancel = (): void => {
    this._close(ConfirmationAction.CANCEL);
  };

  confirm = (): void => {
    this._close(ConfirmationAction.CONFIRM);
  };

  getCheckboxStatus = (): boolean => {
    return this.form.get('checkbox').value;
  };

  private _close = (action: ConfirmationAction): void => {
    this._mdDialogRef.close({ action, checkbox: this.getCheckboxStatus() });
  };
}
