import {
  Component,
  HostListener,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
  ViewEncapsulation,
} from '@angular/core';
import { PhoneNumber } from './models/navigation.model';
import { environment } from '../../../../environments/environment';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { DialogService } from 'src/app/shared/services/dialog.service';
import { LoginComponent } from 'src/app/modules/home/components/login/login.component';
import { RegistrationComponent } from 'src/app/modules/home/components/registration/registration.component';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { LoginService } from '../../../modules/home/services/login.service';
import { Role } from '../../../shared/models/role.model';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'ei-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class NavigationComponent implements OnInit, OnDestroy {
  @ViewChild(TemplateRef) _content: TemplateRef<any>;
  overlayRef: OverlayRef;
  phoneNumber: PhoneNumber;
  showMobileMenu = false;
  appUrl: string;
  currentRoute: string;
  lang: string;

  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    if (event.target.innerWidth >= 1200) {
      this.hideMenu();
    }
  }
  constructor(
    private _router: Router,
    private _dialogService: DialogService,
    private _scrollStrategyOptions: ScrollStrategyOptions,
    private _overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _loginService: LoginService,
    private _userService: UserService,
  ) {
    this.appUrl = environment.appUrl;
    this.phoneNumber = {
      label: '+49 (0)30 887 233 0',
      value: '+490308872330',
    };

    this.overlayRef = this._overlay.create({
      positionStrategy: this._overlay.position().global().right().centerVertically(),
      hasBackdrop: true,
      scrollStrategy: this._scrollStrategyOptions.block(),
    });
  }

  ngOnInit(): void {
    this._router.events
      .pipe(
        filter((event: RouterEvent): boolean => event instanceof NavigationEnd),
        takeUntil(this._unsubscribe$),
      )
      .subscribe((event: NavigationEnd): void => {
        this.hideMenu();

        this.currentRoute = event.urlAfterRedirects;

        const urlRoutes = this.currentRoute.split('/');

        this.lang = '';
        if (urlRoutes.length && urlRoutes[1] && urlRoutes[1].split('#')[0] === 'en') {
          this.lang = 'en';
        }
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next(true);
    this._unsubscribe$.complete();
  }

  hasSellerRole = () => this._userService.hasRole(Role.SELLER);

  toggleMobileMenu = (): void => {
    this.showMobileMenu ? this.hideMenu() : this.showMenu();
  };

  openLoginDialog = (): void => {
    this.hideMenu();
    this._dialogService.open(LoginComponent);
  };

  openRegistrationDialog = (): void => {
    this.hideMenu();
    this._dialogService
      .open(RegistrationComponent, { panelClass: 'registration-dialog' })
      .afterClosed()
      .subscribe((data?: { openDialog: boolean }): void => {
        if (data?.openDialog) {
          this.openLoginDialog();
        }
      });
  };

  hideMenu(): void {
    this.showMobileMenu = false;
    this.overlayRef.detach();
  }

  showMenu(): void {
    this.showMobileMenu = true;

    this.overlayRef.attach(new TemplatePortal(this._content, this._viewContainerRef));

    this.overlayRef.backdropClick().subscribe(() => {
      this.overlayRef.detach();
    });
  }

  isLoggedIn = (): boolean => this._loginService.isLoggedIn();

  logout = (event: Event): void => {
    event.preventDefault();
    this._loginService.logout();
    this.toggleMobileMenu();
  };

  getRouterLink(destination: string): string {
    return this.lang ? `/${this.lang}/${destination}` : `/${destination}`;
  }
}
