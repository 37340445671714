import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { SnackbarData, SnackbarType } from '../models/snackbar.model';

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  private readonly _defaultConfig: MatSnackBarConfig<SnackbarData>;

  constructor(private _snackBar: MatSnackBar) {
    this._defaultConfig = {
      duration: 4000,
      verticalPosition: 'top',
    };
  }

  success = (message: string, config?: MatSnackBarConfig<SnackbarData>): void => {
    this._snackBar.openFromComponent(SnackbarComponent, this.mergeConfig(message, SnackbarType.SUCCESS, config));
  };

  warn = (message: string, config?: MatSnackBarConfig<SnackbarData>): void => {
    this._snackBar.openFromComponent(SnackbarComponent, this.mergeConfig(message, SnackbarType.WARN, config));
  };

  error = (message: string, config?: MatSnackBarConfig<SnackbarData>): void => {
    this._snackBar.openFromComponent(SnackbarComponent, this.mergeConfig(message, SnackbarType.ERROR, config));
  };

  private mergeConfig(
    message: string,
    type: SnackbarType,
    config: MatSnackBarConfig<SnackbarData>,
  ): MatSnackBarConfig<SnackbarData> {
    const configWithtype: MatSnackBarConfig<SnackbarData> = {
      ...this._defaultConfig,
      ...config,
      data: {
        ...config?.data,
        message,
        type,
      },
    };

    return configWithtype;
  }
}
