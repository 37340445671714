<div id="ei-user-account-selector" [formGroup]="form">
  <div class="account-type selected">
    <mat-icon class="icon">check</mat-icon>
    <img src="assets/images/icons/account-types/buyer-green.png" alt="Buyer" i18n-alt />
    <h4 i18n>Buyer</h4>
    <p i18n>Timely</p>
    <p i18n>Statistics</p>
    <ei-checkbox *ngIf="editMode" formControlName="buyer" i18n>Select</ei-checkbox>
  </div>
  <div
    class="account-type"
    [class.selected]="sellerSelected()"
    [class.editable]="editMode && !sellerPending"
    [class.pending]="editMode && sellerPending"
    (click)="toggleSellerSelected()"
  >
    <mat-icon class="icon" *ngIf="!sellerPending">check</mat-icon>
    <mat-icon class="icon" *ngIf="sellerPending">hourglass_bottom</mat-icon>
    <img [src]="sellerIcon" alt="Seller" i18n-alt />
    <h4 i18n>Seller</h4>
    <p i18n>Exclusive</p>
    <p i18n>Offers</p>
    <ei-checkbox *ngIf="editMode" formControlName="seller" i18n>Select</ei-checkbox>
  </div>
</div>
