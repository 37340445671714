import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { SellerRoleGuard } from './shared/guards/seller.guard';

const commonPaths = [
  {
    path: '',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'renew/:token',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'resend/:token',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'register/:token',
    loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'team',
    loadChildren: () => import('./modules/team/team.module').then((m) => m.TeamModule),
  },
  {
    path: 'career',
    loadChildren: () => import('./modules/career/career.module').then((m) => m.CareerModule),
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/about-us/about-us.module').then((m) => m.AboutUsModule),
  },
  {
    path: 'reference',
    loadChildren: () => import('./modules/reference/reference.module').then((m) => m.ReferenceModule),
  },
  {
    path: 'sponsoring',
    loadChildren: () => import('./modules/sponsoring/sponsoring.module').then((m) => m.SponsoringModule),
  },
  {
    path: 'buy',
    loadChildren: () => import('./modules/buy/buy.module').then((m) => m.BuyModule),
  },
  {
    path: 'sell',
    loadChildren: () => import('./modules/sell/sell.module').then((m) => m.SellModule),
  },
  {
    path: 'my-account',
    loadChildren: () => import('./modules/my-account/my-account.module').then((m) => m.MyAccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'my-objects',
    loadChildren: () => import('./modules/my-objects/my-objects.module').then((m) => m.MyObjectsModule),
    canActivate: [AuthGuard, SellerRoleGuard],
  },
  {
    path: 'my-account',
    loadChildren: () => import('./modules/my-account/my-account.module').then((m) => m.MyAccountModule),
    canActivate: [AuthGuard],
  },
  {
    path: 'data-protection',
    loadChildren: () => import('./modules/data-protection/data-protection.module').then((m) => m.DataProtectionModule),
  },
  {
    path: 'imprint',
    loadChildren: () => import('./modules/imprint/imprint.module').then((m) => m.ImprintModule),
  },
];

const routes: Routes = [
  {
    path: '',
    children: commonPaths,
  },
  {
    path: ':lang',
    children: commonPaths,
  },
  {
    path: '**',
    redirectTo: '',
    pathMatch: 'full',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled',
      anchorScrolling: 'enabled',
      scrollOffset: [0, 132],
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
