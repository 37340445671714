import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeadingComponent } from './components/heading/heading.component';
import { ProfilePreviewComponent } from './components/profile-preview/profile-preview.component';
import { MarkdownToHtmlPipe } from './pipes/markdown-to-html.pipe';
import { ButtonComponent } from './components/button/button.component';
import { ReferenceListComponent } from './components/reference-list/reference-list.component';
import { ReferencePreviewComponent } from './components/reference-preview/reference-preview.component';
import { NewsListComponent } from './components/news-list/news-list.component';
import { NewsPreviewComponent } from './components/news-preview/news-preview.component';
import { PaginationComponent } from './components/pagination/pagination.component';
import { MarkdownComponent } from './components/markdown/markdown.component';
import { ArrowListComponent } from './components/arrow-list/arrow-list.component';
import { RouterModule } from '@angular/router';
import { GoTopButtonComponent } from './components/go-top-button/go-top-button.component';
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { InputComponent } from './components/input/input.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { SnackbarComponent } from './components/snackbar/snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { DropdownComponent } from './components/dropdown/dropdown.component';
import { OfferPreviewComponent } from './components/offer-preview/offer-preview.component';
import { OfferListComponent } from './components/offer-list/offer-list.component';
import { ImageCarouselComponent } from './components/image-carousel/image-carousel.component';
import { InfoBoxComponent } from './components/info-box/info-box.component';
import { FormFieldErrorComponent } from './components/form-field-error/form-field-error.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { SelectComponent } from './components/select/select.component';
import { MatInputModule } from '@angular/material/input';
import { CategoryComponent } from './components/category/category.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { DateAdapter, MatNativeDateModule, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { DatePickerCustomHeaderComponent } from './components/date-picker-custom-header/date-picker-custom-header.component';
import { EIDateAdapter } from './helpers/EIDateAdapter';
import { TimePickerComponent } from './components/time-picker/time-picker.component';
import { CategorySelectorComponent } from './components/category-selector/category-selector.component';
import { FileUploaderComponent } from './components/file-uploader/file-uploader.component';
import { YearPickerComponent } from './components/year-picker/year-picker.component';
import { ContactFormPartComponent } from './components/contact-form-part/contact-form-part.component';
import { TextareaComponent } from './components/textarea/textarea.component';
import { ContactFormComponent } from './components/contact-form/contact-form.component';
import { FormSubmissionStatusComponent } from './components/form-submission-status/form-submission-status.component';
import { GoBackLinkComponent } from './components/go-back-link/go-back-link.component';
import { MultiSelectFilterComponent } from './components/multi-select-filter/multi-select-filter.component';
import { RangeFilterComponent } from './components/range-filter/range-filter.component';
import { CategoryDropdownSelectorComponent } from './components/category-dropdown-selector/category-dropdown-selector.component';
import { SearchComponent } from './components/search/search.component';
import { SortComponent } from './components/sort/sort.component';
import { UserAccountSelectorComponent } from './components/user-account-selector/user-account-selector.component';
import { EditButtonComponent } from './components/edit-button/edit-button.component';
import { CategoryLabelComponent } from './components/category-label/category-label.component';
import { CookiePolicyDialogComponent } from './components/cookie-policy-dialog/cookie-policy-dialog.component';
import { ConfirmationDialogComponent } from './components/confirmation-dialog/confirmation-dialog.component';
import { MatButtonModule } from '@angular/material/button';

export const MATERIAL_DATEPICKER_FORMATS = {
  parse: {
    dateInput: 'DD/MMM/YYYY',
  },
  display: {
    dateInput: 'DD MMM YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'DD/MMM/YYYY',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

@NgModule({
  declarations: [
    HeadingComponent,
    ProfilePreviewComponent,
    MarkdownToHtmlPipe,
    ButtonComponent,
    ReferenceListComponent,
    ReferencePreviewComponent,
    NewsListComponent,
    NewsPreviewComponent,
    PaginationComponent,
    MarkdownComponent,
    ArrowListComponent,
    GoTopButtonComponent,
    InputComponent,
    SnackbarComponent,
    DropdownComponent,
    OfferPreviewComponent,
    OfferListComponent,
    ImageCarouselComponent,
    InfoBoxComponent,
    FormFieldErrorComponent,
    CheckboxComponent,
    SelectComponent,
    TextareaComponent,
    CategoryComponent,
    DatePickerComponent,
    DatePickerCustomHeaderComponent,
    TimePickerComponent,
    CategorySelectorComponent,
    FileUploaderComponent,
    YearPickerComponent,
    ContactFormPartComponent,
    FileUploaderComponent,
    TextareaComponent,
    ContactFormComponent,
    FormSubmissionStatusComponent,
    GoBackLinkComponent,
    ContactFormPartComponent,
    MultiSelectFilterComponent,
    RangeFilterComponent,
    CategoryDropdownSelectorComponent,
    SearchComponent,
    SortComponent,
    UserAccountSelectorComponent,
    EditButtonComponent,
    CategoryLabelComponent,
    CookiePolicyDialogComponent,
    ConfirmationDialogComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatDialogModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatSnackBarModule,
    MatIconModule,
    MatInputModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
  ],
  exports: [
    HeadingComponent,
    ProfilePreviewComponent,
    MarkdownToHtmlPipe,
    ButtonComponent,
    ReferenceListComponent,
    ReferencePreviewComponent,
    NewsListComponent,
    NewsPreviewComponent,
    PaginationComponent,
    MarkdownComponent,
    ArrowListComponent,
    GoTopButtonComponent,
    InputComponent,
    DropdownComponent,
    OfferListComponent,
    ImageCarouselComponent,
    InfoBoxComponent,
    CheckboxComponent,
    SelectComponent,
    OfferPreviewComponent,
    ReactiveFormsModule,
    TextareaComponent,
    MatInputModule,
    DatePickerComponent,
    MatInputModule,
    MatDatepickerModule,
    TimePickerComponent,
    CategorySelectorComponent,
    FileUploaderComponent,
    YearPickerComponent,
    FileUploaderComponent,
    TextareaComponent,
    ContactFormComponent,
    FormSubmissionStatusComponent,
    GoBackLinkComponent,
    ContactFormPartComponent,
    FormSubmissionStatusComponent,
    MultiSelectFilterComponent,
    RangeFilterComponent,
    CategoryDropdownSelectorComponent,
    SearchComponent,
    SortComponent,
    UserAccountSelectorComponent,
    EditButtonComponent,
    CategoryLabelComponent,
    CookiePolicyDialogComponent,
  ],
  providers: [
    { provide: DateAdapter, useClass: EIDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MATERIAL_DATEPICKER_FORMATS },
  ],
})
export class SharedModule {}
