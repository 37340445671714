import { Injectable } from '@angular/core';
import { User, UserProfile } from '../models/user.model';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ClientApiService } from 'src/app/core/services/api/client-api.service';
import { take, tap } from 'rxjs/operators';
import { Role } from '../models/role.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _user: User;

  user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
  userProfile$: Subject<UserProfile> = new Subject<UserProfile>();

  constructor(private _clientApiService: ClientApiService) {}

  getUser = (): Observable<User> =>
    this._clientApiService.get('user/detail').pipe(
      take(1),
      tap((user: User) => {
        this._user = {
          ...user,
          avatarLink: user.avatarLink ? user.avatarLink : 'assets/images/avatar-default.svg',
        };
        this.user$.next(this._user);
      }),
    );

  hasRole = (requiredRole: Role): boolean => requiredRole === this._user?.role;

  uploadAvatar = (formData: FormData): Observable<any> =>
    this._clientApiService.post('user/avatar', formData).pipe(take(1));

  updateUserProfile = (profile: Partial<UserProfile>): Observable<UserProfile> =>
    this._clientApiService.patch('user/profile', profile).pipe(
      take(1),
      tap({
        next: () => {
          this.getUserProfile();
        },
      }),
    );

  getUserProfile = (): void => {
    this._clientApiService
      .get('user/profile')
      .pipe(take(1))
      .subscribe((profile: UserProfile): void => {
        this.userProfile$.next(profile);
      });
  };

  resetPassword = (payload: { email: string }): Observable<void> =>
    this._clientApiService.post('user/link', payload).pipe(take(1));

  requestAccountDelete = (): Observable<void> => this._clientApiService.delete('user/deregister').pipe(take(1));

  renewPassword = (link: string, password: string): Observable<void> =>
    this._clientApiService.put('user/link/password', { link, password }).pipe(take(1));

  changePassword = (newPassword: string): Observable<void> =>
    this._clientApiService.patch('user/password', newPassword).pipe(take(1));

  confirmEmail = (payload: { link: string }): Observable<void> =>
    this._clientApiService.post('user/confirmation', payload).pipe(take(1));

  resendConfirmationLink = (payload: { link: string }): Observable<void> =>
    this._clientApiService.post('user/registration/link', payload).pipe(take(1));
}
