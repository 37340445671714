<div *ngIf="status" id="ei-form-submission-status" [class]="status">
  <mat-icon class="icon">{{ status === FormSubmissionStatus.Success ? 'done' : 'error' }}</mat-icon>
  <div class="headline" i18n>{{ status === FormSubmissionStatus.Success ? headline : 'Something went wrong' }}</div>
  <div class="sub-headline" i18n>
    {{
      status === FormSubmissionStatus.Success
        ? subHeadline
        : 'Please check form correctness or contact system administator'
    }}
  </div>
</div>
