import { Injectable } from '@angular/core';
import { ClientApiService } from '../../../core/services/api/client-api.service';
import { Observable } from 'rxjs';
import { RegistrationData } from '../models/registration.model';

@Injectable({
  providedIn: 'root',
})
export class RegistrationService {
  constructor(private _clientApiService: ClientApiService) {}

  register = (payload: RegistrationData): Observable<any> => this._clientApiService.post('user', payload);
}
