<div id="ei-reference-list" [className]="customClass">
  <div class="reference-list">
    <div class="reference-list-item" *ngFor="let item of reference">
      <ei-reference-preview [reference]="item"></ei-reference-preview>
    </div>
  </div>
  <div *ngIf="displayMoreSection" class="more">
    <ei-button color="secondaryWhite" (buttonClick)="onMoreClick()" i18n>See all</ei-button>
  </div>
</div>
