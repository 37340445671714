import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ei-edit-button',
  templateUrl: './edit-button.component.html',
  styleUrls: ['./edit-button.component.scss'],
})
export class EditButtonComponent {
  @Input() disabled: boolean;
  @Input() editMode: boolean;

  @Output() edit: EventEmitter<void> = new EventEmitter<void>();
  @Output() save: EventEmitter<void> = new EventEmitter<void>();

  constructor() {}

  onClick = (): void => {
    if (!this.disabled) {
      this.editMode ? this.save.emit() : this.edit.emit();
    }
  };
}
