import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MediaType, UploaderState, UploadingFunction } from '../../models/file.model';
import { FileService } from '../../services/file.service';
import { SnackbarService } from '../../services/snackbar.service';

@Component({
  selector: 'ei-file-uploader',
  templateUrl: './file-uploader.component.html',
  styleUrls: ['./file-uploader.component.scss'],
})
export class FileUploaderComponent implements OnInit {
  state: UploaderState;
  UploaderState = UploaderState;
  acceptType: string;
  private _canceled$ = new Subject();

  @Input() uploadFunction: UploadingFunction;
  @Input() mediaType = MediaType.Image;

  @Output() uploadedFile = new EventEmitter<any>();

  @ViewChild('fileUploader') fileUploader: ElementRef<HTMLInputElement>;

  @HostListener('dragover', ['$event'])
  onDragOver(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.state = UploaderState.DragOver;
  }

  @HostListener('dragleave', ['$event'])
  onDragLeave(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();

    this.state = UploaderState.Default;
  }

  @HostListener('drop', ['$event'])
  onDrop(event: DragEvent): void {
    event.stopPropagation();
    event.preventDefault();

    const file = event.dataTransfer.files.item(0);
    this.uploadFile(file);
  }

  constructor(private _fileService: FileService, private _snackbarService: SnackbarService) {}

  ngOnInit(): void {
    this.acceptType = this._fileService
      .getAcceptedTypesBy(this.mediaType)
      .map((el) => `.${el}`)
      .join(',');
  }

  onFileChange(files: FileList): void {
    this.uploadFile(files.item(0));
  }

  onCancel(event): void {
    event.preventDefault();
    event.stopPropagation();
    this._canceled$.next();
    this.state = UploaderState.Default;
  }

  uploadFile(file: File): void {
    this.fileUploader.nativeElement.value = '';

    if (!file) {
      return;
    }

    if (this._fileService.validateFileType(file, this.mediaType) && this._fileService.validateFileSize(file)) {
      this.state = UploaderState.IsUploading;

      this.uploadFunction(file)
        .pipe(takeUntil(this._canceled$))
        .subscribe({
          next: (response: any) => {
            this.uploadedFile.emit(response);
            this.state = UploaderState.Loaded;

            setTimeout(() => {
              this.state = UploaderState.Default;
            }, 3000);
          },
          error: () => {
            this._snackbarService.error($localize`Unable to uploaed file, please try again`);
          },
        });
    } else {
      this.state = UploaderState.BadFormat;

      setTimeout(() => {
        this.state = UploaderState.Default;
      }, 3000);
    }
  }
}
