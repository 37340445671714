<div id="ei-user-menu">
  <div class="user-menu">
    <div class="avatar">
      <img [src]="user?.avatarLink || 'assets/images/avatar-default.svg'" />
    </div>
    <div class="icon">
      <mat-icon>arrow_drop_down</mat-icon>
    </div>
    <div class="dropdown-content">
      <a class="dropdown-item" routerLink="/my-account" routerLinkActive="active">
        <span i18n> My account </span>
      </a>
      <a class="dropdown-item" *ngIf="hasSellerRole()" (click)="redirect()" routerLinkActive="active">
        <span i18n> My objects </span>
      </a>
      <a class="dropdown-item" (click)="logout()">
        <span i18n> Logout </span>
      </a>
    </div>
  </div>
</div>
