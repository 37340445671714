<div id="ei-news-preview" [class.show-more-button]="showMoreButtonOnDesktop" [routerLink]="getNewsLink()">
  <div class="picture">
    <div>
      <img [src]="news.pictureUrl" [alt]="news.title" />
    </div>
  </div>
  <div class="content">
    <h3>{{ news.title }}</h3>
    <div class="intro" [innerHTML]="news.intro | markdownToHtml"></div>
    <div class="more">
      <ei-button (buttonClick)="onMoreClick()" [small]="true" i18n>More</ei-button>
    </div>
  </div>
</div>
