import { Component, Output, EventEmitter, Input, ViewEncapsulation } from '@angular/core';
import { Pagination } from '../../models/pagination.model';

@Component({
  selector: 'ei-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class PaginationComponent {
  @Input() pageNumber: number;
  @Input() perPage: number;
  @Input() total: number;

  @Output() pageChange: EventEmitter<Pagination> = new EventEmitter<Pagination>();

  onPrevPageClick = (): void => {
    if (this.canDecreasePage()) {
      this.pageNumber = this.pageNumber - 1;
      this.pageChange.emit({
        pageNumber: this.pageNumber,
        perPage: this.perPage,
      });
    }
  };

  onNextPageClick = (): void => {
    if (this.canIncreasePage()) {
      this.pageNumber = this.pageNumber + 1;
      this.pageChange.emit({
        pageNumber: this.pageNumber,
        perPage: this.perPage,
      });
    }
  };

  canDecreasePage = (): boolean => this.pageNumber > 0;

  canIncreasePage = (): boolean => this.pageNumber < Math.ceil(this.total / this.perPage) - 1;
}
