import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { LoginService } from 'src/app/modules/home/services/login.service';
import { tap } from 'rxjs/operators';
import { DialogService } from '../services/dialog.service';
import { LoginComponent } from 'src/app/modules/home/components/login/login.component';

@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(private _loginService: LoginService, private _dialogService: DialogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap(
        () => {},
        (err: any) => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this._loginService.logout();
              this._dialogService.closeAll();
              this._dialogService.open(LoginComponent);
            }
          }
        },
      ),
    );
  }
}
