<div id="ei-confirmation-dialog">
  <div class="close">
    <mat-icon (click)="cancel()">close</mat-icon>
  </div>
  <h2 mat-dialog-title>{{ data.title }}</h2>
  <mat-dialog-content *ngIf="data.message">{{ data.message }}</mat-dialog-content>
  <form *ngIf="data.checkboxText" [formGroup]="form" class="checkbox">
    <ei-checkbox formControlName="checkbox" i18n>{{ data.checkboxText }}</ei-checkbox>
  </form>
  <mat-dialog-actions>
    <ei-button (buttonClick)="cancel()" [small]="true" color="text">{{ data.cancelText }}</ei-button>
    <ei-button (buttonClick)="confirm()" [small]="true" color="primary">{{ data.confirmText }}</ei-button>
  </mat-dialog-actions>
</div>
