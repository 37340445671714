<div id="ei-category-selector">
  <div class="category-selector">
    <div class="mobile">
      <ei-dropdown (valueSelected)="onCategorySelect($event)">
        <li *ngFor="let item of categories" [attr.data-value]="item" i18n>
          { item, select, APARTMENT_BUILDINGS {Apartment buildings} COMMERCIAL_BUILDINGS {Commercial Buildings} LAND
          {Land} SINGLE_FAMILY_BUILDINGS {Single family buildings} CONDOMINIUMS {Condominiums} }
        </li>
        <li i18n (buttonClick)="onCategorySelect(null)">See all</li>
      </ei-dropdown>
    </div>
    <div class="desktop">
      <ul>
        <li *ngFor="let item of categories" [class.active]="isActive(item)" (click)="onCategorySelect(item)" i18n>
          { item, select, APARTMENT_BUILDINGS {Apartment buildings} COMMERCIAL_BUILDINGS {Commercial Buildings} LAND
          {Land} SINGLE_FAMILY_BUILDINGS {Single family buildings} CONDOMINIUMS {Condominiums} }
        </li>
        <li (click)="onCategorySelect(null)" [class.active]="isActive(null)" i18n>See all</li>
      </ul>
    </div>
  </div>
</div>
