import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { LoginComponent } from 'src/app/modules/home/components/login/login.component';

@Component({
  selector: 'ei-cookie-policy-dialog',
  templateUrl: './cookie-policy-dialog.component.html',
  styleUrls: ['./cookie-policy-dialog.component.scss'],
})
export class CookiePolicyDialogComponent {
  constructor(private _dialogRef: MatDialogRef<LoginComponent>) {}

  closeDialog(): void {
    this._dialogRef.close();
  }

  accept = () => {
    localStorage.setItem('cookie-accepted', JSON.stringify(true));
    this._dialogRef.close();
  };
}
