import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  ViewEncapsulation,
} from '@angular/core';
import { fromEvent } from 'rxjs';

@Component({
  selector: 'ei-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DropdownComponent implements AfterViewInit {
  selected: any;
  open: boolean;

  @Input() buttonColor: string;

  @Output() valueSelected: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild('dropdown') dropdown: ElementRef;

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngAfterViewInit(): void {
    this._setSelected(this.dropdown.nativeElement.firstChild, false);

    fromEvent(this.dropdown.nativeElement, 'click').subscribe((event: MouseEvent): void => {
      this.open = false;
      this._setSelected(event.target as HTMLElement);
    });
  }

  toggleOpen = (): void => {
    this.open = !this.open;
  };

  private _setSelected = (element: HTMLElement, emit: boolean = true): void => {
    const { innerText, dataset } = element;

    this.selected = {
      text: innerText,
      value: dataset.value,
    };

    this._changeDetectorRef.detectChanges();

    if (emit) {
      this.valueSelected.emit(this.selected.value);
    }
  };
}
