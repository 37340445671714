import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize } from 'rxjs/operators';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { LoginService } from '../../services/login.service';
import { ForgotPasswordComponent } from '../forgot-password/forgot-password.component';
import { DialogService } from '../../../../shared/services/dialog.service';

@Component({
  selector: 'ei-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class LoginComponent implements OnInit {
  isError: boolean;
  isLoading: boolean;
  form: FormGroup;

  constructor(
    private _dialogRef: MatDialogRef<LoginComponent>,
    private _snackbarService: SnackbarService,
    private _loginService: LoginService,
    private _fb: FormBuilder,
    private _dialogService: DialogService,
  ) {}

  ngOnInit(): void {
    this.form = this._fb.group({
      login: this._fb.control(null, [Validators.required, Validators.email]),
      password: this._fb.control(null, Validators.required),
    });
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  onForgotPassword = (): void => {
    this._dialogService.closeAll();
    this._dialogService.open(ForgotPasswordComponent);
  };

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    this.isLoading = true;

    this._loginService
      .login(this.form.value)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (): void => {
          this._snackbarService.success($localize`Login Successful.`);
          this.closeDialog();
        },
        error: (): void => {
          this.isError = true;
        },
      });
  }
}
