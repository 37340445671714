<div id="ei-select">
  <label class="placeholder" [class.required]="required" [class.optional]="optional" i18n>{{ placeholder }}</label>

  <select [formControl]="inputControl">
    <option *ngFor="let option of options" [value]="option.value">
      {{ option.name | titlecase }}
    </option>
  </select>

  <ei-form-field-error [control]="inputControl"></ei-form-field-error>
</div>
