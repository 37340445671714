<div id="ei-forgot-password">
  <div class="button-container">
    <button type="submit" class="close-button" (click)="closeDialog()">
      <mat-icon>close</mat-icon>
    </button>
  </div>
  <ei-heading header="Forgot password" i18n-header></ei-heading>
  <form [formGroup]="form" (ngSubmit)="submit()">
    <ei-input formControlName="email" placeholder="E-mail" type="email" [optional]="false"></ei-input>
    <ei-button color="primary" [disabled]="form.invalid" [isLoading]="isLoading" i18n>Submit</ei-button>
  </form>
</div>
