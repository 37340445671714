<div id="ei-offer-preview" [class.tablet-preview]="tabletPreview">
  <div class="image">
    <ei-image-carousel [urls]="offer.images" [exclusive]="offer.exclusive"></ei-image-carousel>
  </div>
  <div class="wrapper">
    <div class="main-info">
      <div class="category">
        <ei-category [category]="offer.category"></ei-category>
      </div>
      <div class="title">{{ offer.title }}</div>
      <div class="address">
        <span>{{ offer.postalCode }}</span>
        <span>{{ offer.city }}</span>
        <span>{{ offer.district }}</span>
      </div>
      <div class="price">{{ offer.price | number: '1.0-3' }} €</div>
    </div>
    <div class="additional-info" [class.no-link]="!showLink">
      <div class="additional-items">
        <mat-icon svgIcon="building"></mat-icon>
        <div class="category">
          <ei-category [category]="offer.category"></ei-category>
        </div>
        <mat-icon svgIcon="layers" class="size-icon">aspect_ratio</mat-icon>
        <div class="size">{{ offer.totalSize }} m²</div>
      </div>
      <div class="show-more" *ngIf="showLink">
        <ei-button (buttonClick)="redirect()" class="show-more-desktop" [small]="true" i18n>More info</ei-button>
      </div>
    </div>
  </div>
</div>
