import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { MediaType } from '../models/file.model';

@Injectable({
  providedIn: 'root',
})
export class FileService {
  documentTypes = ['doc', 'docx', 'pdf'];
  imagesTypes = ['png', 'jpg', 'svg'];

  getAcceptedTypesBy(mediaType: MediaType): string[] {
    switch (mediaType) {
      case MediaType.Image:
        return this.imagesTypes;
      case MediaType.Document:
        return this.documentTypes;
      default:
        break;
    }
  }

  validateFileSize = (file: File): boolean => {
    return file.size < environment.fileSizeLimit;
  };

  validateFileType(file: File, mediaType: MediaType): boolean {
    const extension = file.name.split('.').pop();
    const acceptedMediaTyped = this.getAcceptedTypesBy(mediaType);

    return acceptedMediaTyped.includes(extension);
  }
}
