<div id="ei-navigation">
  <div class="top-bar">
    <div class="contact-bar">
      <div class="phone">
        <span class="message" i18n>Call directly</span>
        <mat-icon>phone</mat-icon>
        <a class="phone-number" href="tel:{{ phoneNumber.value }}">{{ phoneNumber.label }}</a>
      </div>
      <ul class="language-selector">
        <li>
          <a [href]="appUrl + currentRoute?.replace(lang ? '/' + lang : '', '')"
            ><img src="assets/images/icons/flags/de.svg" alt="German" i18n-alt
          /></a>
        </li>
        <li>
          <a [href]="appUrl + (lang ? '/en' : '/en/') + currentRoute?.replace('/' + lang, '')"
            ><img src="assets/images/icons/flags/gb.svg" alt="English" i18n-alt
          /></a>
        </li>
      </ul>
    </div>
  </div>
  <div class="navigation">
    <div class="logo">
      <div class="side"></div>
      <a [routerLink]="['/', lang]">
        <img src="assets/images/logo.svg" alt="Engelhardt Immobilien" />
      </a>
    </div>
    <div class="menu">
      <ul>
        <li>
          <a [routerLink]="getRouterLink('/buy')" routerLinkActive="active" i18n>Buy</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/sell')" routerLinkActive="active" i18n>Sell</a>
        </li>
        <li>
          <div class="dropdown">
            <button>
              <span class="corporate-text" i18n>Corporate</span>
              <mat-icon>arrow_drop_down</mat-icon>
            </button>
            <div class="dropdown-content">
              <a class="dropdown-item" [routerLink]="getRouterLink('/about-us')" routerLinkActive="active">
                <span i18n>About us</span>
              </a>
              <a class="dropdown-item" [routerLink]="getRouterLink('/team')" routerLinkActive="active">
                <span i18n>Team</span>
              </a>
              <a class="dropdown-item" [routerLink]="getRouterLink('/reference')" routerLinkActive="active">
                <span i18n>Reference</span>
              </a>
              <a class="dropdown-item" [routerLink]="getRouterLink('/sponsoring')" routerLinkActive="active">
                <span i18n>Sponsoring</span>
              </a>
              <a class="dropdown-item" [routerLink]="getRouterLink('/career')" routerLinkActive="active">
                <span i18n>Career</span>
              </a>
            </div>
          </div>
        </li>
        <li class="contact">
          <a [routerLink]="getRouterLink('/')" fragment="contact" i18n>Contact</a>
        </li>
      </ul>
      <mat-icon svgIcon="menu" [className]="'mobile-menu-open-trigger'" (click)="toggleMobileMenu()"></mat-icon>
    </div>
  </div>
  <ng-template>
    <div class="mobile-menu" [ngClass]="showMobileMenu ? '' : 'hidden'">
      <mat-icon [className]="'mobile-menu-close-trigger'" (click)="toggleMobileMenu()">close</mat-icon>
      <ul class="mobile-navigation">
        <li>
          <a [routerLink]="getRouterLink('/buy')" routerLinkActive="active" i18n>Buy</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/sell')" routerLinkActive="active" i18n>Sell</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/about-us')" routerLinkActive="active" i18n>About us</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/team')" routerLinkActive="active" i18n>Team</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/reference')" routerLinkActive="active" i18n>Reference</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/sponsoring')" routerLinkActive="active" i18n>Sponsoring</a>
        </li>
        <li>
          <a [routerLink]="getRouterLink('/career')" routerLinkActive="active" i18n>Career</a>
        </li>
        <li class="contact">
          <a [routerLink]="getRouterLink('/')" fragment="contact" i18n>Contact</a>
        </li>
        <li [hidden]="isLoggedIn()" class="hide-bottom-border">
          <ei-button color="navButton" (buttonClick)="openRegistrationDialog()" [small]="true" i18n>Register</ei-button>
        </li>
        <li [hidden]="isLoggedIn()" class="hide-bottom-border">
          <ei-button color="navButton" (buttonClick)="openLoginDialog()" [small]="true" i18n>Log in</ei-button>
        </li>
        <li [hidden]="!isLoggedIn()" class="account-section-start">
          <a [routerLink]="getRouterLink('/my-account')" routerLinkActive="active" i18n>My account</a>
        </li>
        <li [hidden]="!isLoggedIn() || !hasSellerRole()">
          <a [routerLink]="getRouterLink('/my-objects')" routerLinkActive="active" i18n>My objects</a>
        </li>
        <li [hidden]="!isLoggedIn()">
          <ei-button color="navButton" (click)="logout($event)" [small]="true" i18n>Logout</ei-button>
        </li>
      </ul>
      <ul class="mobile-language-selector">
        <li>
          <a [href]="appUrl + currentRoute?.replace(lang ? '/' + lang : '', '')">
            <img src="assets/images/icons/flags/de.svg" alt="German" i18n-alt />
            <span i18n>German</span>
          </a>
        </li>
        <li>
          <a [href]="appUrl + (lang ? '/en' : '/en/') + currentRoute?.replace('/' + lang, '')">
            <img src="assets/images/icons/flags/gb.svg" alt="English" i18n-alt />
            <span i18n>English</span>
          </a>
        </li>
      </ul>
    </div>
  </ng-template>
</div>
