import { Component, OnInit, Optional, Self } from '@angular/core';
import * as moment from 'moment';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'ei-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
})
export class TimePickerComponent implements OnInit {
  options: string[] = [];
  inputControl: AbstractControl;
  startTime = {
    hours: 7,
    minutes: 0,
  };
  endTime = {
    hours: 21,
    minutes: 0,
  };
  timeWindowInMinutes = 30;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.inputControl = this.control.control;

    const startTime = moment().hours(this.startTime.hours).minute(this.startTime.minutes).second(0);
    const endTime = moment().hours(this.endTime.hours).minute(this.endTime.minutes).second(0);

    for (startTime; startTime.isSameOrBefore(endTime); startTime.add(this.timeWindowInMinutes, 'minutes')) {
      const hour = startTime.hours();
      const minutes = startTime.minutes();

      const convertedHour = hour < 10 ? `0${hour}` : `${hour}`;
      const convertedMinutes = minutes < 10 ? `0${minutes}` : `${minutes}`;

      this.options.push(`${convertedHour}:${convertedMinutes}`);
    }
  }

  onChangeFn = (_: any) => {};
  onTouchedFn = () => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(obj: any): void {}

  onChange(): void {
    this.onChangeFn(this.inputControl.value);
  }
}
