import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { finalize, take } from 'rxjs/operators';
import { AccountType } from 'src/app/shared/models/account-type.model';
import { SelectOption } from 'src/app/shared/models/select-option.model';
import { SnackbarService } from 'src/app/shared/services/snackbar.service';
import { LoginComponent } from '../login/login.component';
import { RegistrationService } from '../../services/registration.service';
import { Role } from 'src/app/shared/models/role.model';
import { CustomValidator } from 'src/app/shared/validators/custom.validator';
import { Router } from '@angular/router';

@Component({
  selector: 'ei-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RegistrationComponent implements OnInit {
  isLoading: boolean;
  form: FormGroup;
  accountTypes: SelectOption[];
  sellerSelected = false;

  constructor(
    private _dialogRef: MatDialogRef<LoginComponent>,
    private _snackbarService: SnackbarService,
    private _registrationService: RegistrationService,
    private _fb: FormBuilder,
    private _router: Router,
  ) {}

  ngOnInit(): void {
    this.getAccountTypes();

    this.form = this._fb.group(
      {
        type: this._fb.control(Role.BUYER, [Validators.required]),
        firstName: this._fb.control(null, [Validators.required]),
        lastName: this._fb.control(null, [Validators.required]),
        email: this._fb.control(null, [Validators.required, Validators.email]),
        password: this._fb.control(null, [Validators.required, CustomValidator.passwordStrength()]),
        confirmPassword: this._fb.control(null, [Validators.required, CustomValidator.passwordStrength()]),
        street: this._fb.control(null, [Validators.required]),
        postalCode: this._fb.control(null, [Validators.required]),
        city: this._fb.control(null, [Validators.required]),
        regulationAccepted: this._fb.control(null, Validators.requiredTrue),
      },
      { validators: this.checkPasswords },
    );
  }

  onUserSelect(selected: boolean): void {
    this.form.get('type').setValue(selected ? Role.SELLER : Role.BUYER);
  }

  checkPasswords(group: FormGroup): void {
    const password = group.get('password').value;
    const confirmPassword = group.get('confirmPassword').value;
    if (!password || !confirmPassword) {
      return;
    }

    if (password === confirmPassword) {
      group.get('confirmPassword').setErrors({ passwordDiffers: false });
      group.get('confirmPassword').updateValueAndValidity({ onlySelf: true });
    } else {
      group.get('confirmPassword').setErrors({ passwordDiffers: true });
    }
  }

  getAccountTypes(): void {
    this.accountTypes = Object.keys(AccountType).map(
      (key: string): SelectOption => ({
        name: key.replace(/_/g, ' '),
        value: key,
      }),
    );
  }

  closeDialog(): void {
    this._dialogRef.close();
  }

  navigateToLegalInfoPage = (): void => {
    this._router.navigateByUrl('data-protection');
    this.closeDialog();
  };

  openLoginPage(): void {
    this._dialogRef.close({ openDialog: true });
  }

  submit(): void {
    if (this.form.invalid) {
      return;
    }

    const { regulationAccepted, confirmPassword, ...rest } = this.form.value;

    this.isLoading = true;
    this._registrationService
      .register(rest)
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
        }),
      )
      .subscribe({
        next: (): void => {
          this._snackbarService.success($localize`Registration successful`);
          this._dialogRef.close();
        },
        error: (error: any): void => {
          if (error.status === 409) {
            this.form.get('email').setErrors({ emailTaken: true });
          } else if (error.status < 500) {
            this._snackbarService.error($localize`Registration failed. Please try again.`);
          }
        },
      });
  }
}
