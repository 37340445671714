<div id="ei-range-filter" [formGroup]="form" #rangeFilter>
  <div class="mobile">
    <div>
      <label class="placeholder">{{ placeholder }}</label>
    </div>

    <span i18n>From</span>
    <div class="search" (click)="openPanel()">
      <ei-input formControlName="from" type="number"></ei-input>
      <button *ngIf="formControls.from.value" mat-icon-button (click)="clear('from')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <span i18n>To</span>
    <div class="search" (click)="openPanel()">
      <ei-input formControlName="to" type="number"></ei-input>
      <button *ngIf="formControls.to.value" mat-icon-button (click)="clear('to')">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <div>
      <small *ngIf="formControls.from.errors" i18n>From value must be lower or equal to to.</small>
    </div>
  </div>
  <div class="desktop">
    <div class="input">
      <label class="placeholder">{{ placeholder }}</label>

      <div class="search" (click)="openPanel()">
        <span i18n>Select</span>
        <mat-icon>{{ open ? 'arrow_drop_up' : 'arrow_drop_down' }}</mat-icon>
      </div>
    </div>
    <div class="dropdown" #dropdown [class.show]="open">
      <span i18n>From</span>
      <ei-input formControlName="from" type="number"></ei-input>
      <span i18n>To</span>
      <ei-input formControlName="to" type="number"></ei-input>
    </div>
    <div class="selected-items">
      <li *ngIf="formControls.from.value">
        <div>
          <span i18n>From: </span>
          <span>{{ formControls.from.value }}</span>
        </div>
        <button mat-icon-button (click)="removeItem('from')">
          <mat-icon>close</mat-icon>
        </button>
      </li>
      <li *ngIf="formControls.to.value">
        <div>
          <span i18n>To: </span>
          <span>{{ formControls.to.value }}</span>
        </div>
        <button mat-icon-button (click)="removeItem('to')">
          <mat-icon>close</mat-icon>
        </button>
      </li>
    </div>
  </div>
</div>
