import { Observable } from 'rxjs';

export interface UploadedFile {
  cancelled: boolean;
  completedExceptionally: boolean;
  done: boolean;
  numberOfDependents: number;
}

export type UploadingFunction = (file: File) => Observable<any>;

export enum MediaType {
  Image,
  Document,
}

export enum UploaderState {
  DragOver = 'drag-over',
  BadFormat = 'bad-format',
  Loaded = 'loaded',
  Default = 'default',
  IsUploading = 'is-uploading',
}
