import { Component, Input } from '@angular/core';

@Component({
  selector: 'ei-image-carousel',
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss'],
})
export class ImageCarouselComponent {
  @Input() urls: string[];
  @Input() exclusive: boolean;
  current: number;

  constructor() {
    this.current = 0;
  }

  getSlide(): string {
    return this.urls[this.current] || 'assets/images/placeholder.png';
  }

  getPrev(): void {
    this.current = this.current === 0 ? this.urls.length - 1 : this.current - 1;
  }

  getNext(): void {
    this.current = this.current + 1 === this.urls.length ? 0 : this.current + 1;
  }
}
