import { Component, Input, OnInit, Optional, Self, ViewEncapsulation } from '@angular/core';
import { AbstractControl, NgControl } from '@angular/forms';

@Component({
  selector: 'ei-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TextareaComponent implements OnInit {
  @Input() placeholder: string;
  @Input() required: boolean;
  @Input() optional: boolean;
  @Input() minRows = 4;

  inputControl: AbstractControl;

  constructor(@Self() @Optional() public control: NgControl) {
    if (this.control) {
      this.control.valueAccessor = this;
    }
  }

  ngOnInit(): void {
    this.inputControl = this.control.control;
  }

  onChangeFn = (_: any) => {};
  onTouchedFn = () => {};

  registerOnChange(fn: any): void {
    this.onChangeFn = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouchedFn = fn;
  }

  writeValue(obj: any): void {
    this.inputControl?.setValue(obj);
  }

  onChange(): void {
    this.onChangeFn(this.inputControl.value);
  }
}
