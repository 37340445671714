import { Component, Input, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'ei-info-box',
  templateUrl: './info-box.component.html',
  styleUrls: ['./info-box.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class InfoBoxComponent {
  @Input() text: string;
}
