import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NewsPreview } from '../../models/news.model';

@Component({
  selector: 'ei-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.scss'],
})
export class NewsListComponent {
  @Input() news: NewsPreview[];
  @Input() showAll: boolean;
  @Input() showMoreButtonOnDesktop: boolean;
  @Input() lang: string | undefined;

  constructor(private _router: Router) {}

  onMoreClick = (): void => {
    this._router.navigateByUrl(this.lang ? `/${this.lang}/news` : '/news');
  };
}
