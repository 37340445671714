<div id="ei-image-carousel">
  <img [src]="getSlide()" alt="carousel-item" />
  <div *ngIf="exclusive" class="exclusive">
    <mat-icon>star_rate</mat-icon>
    <span i18n>Exclusive offer</span>
  </div>
  <div *ngIf="urls.length > 1">
    <div class="image-count">{{ current + 1 }} / {{ urls.length }}</div>
    <mat-icon class="arrow-left" (click)="getPrev()">keyboard_arrow_left</mat-icon>
    <mat-icon class="arrow-right" (click)="getNext()">keyboard_arrow_right</mat-icon>
  </div>
</div>
