import { Component, Input } from '@angular/core';
import { Category } from '../../models/category.model';

@Component({
  selector: 'ei-category-label',
  templateUrl: './category-label.component.html',
  styleUrls: ['./category-label.component.scss'],
})
export class CategoryLabelComponent {
  categories = Category;

  @Input() category: Category;
}
