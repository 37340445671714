import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ei-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SearchComponent implements OnInit, OnDestroy {
  search: FormControl;
  private _unsubscribe$: Subject<boolean> = new Subject<boolean>();

  @Output() searchedPhraseChange = new EventEmitter<string>();

  constructor(private _fb: FormBuilder) {
    this.search = this._fb.control(null);
  }

  ngOnInit(): void {
    this.search.valueChanges.pipe(takeUntil(this._unsubscribe$)).subscribe((searchValue: string): void => {
      this.searchedPhraseChange.emit(searchValue);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  clear(): void {
    this.search.setValue('');
  }
}
