<div id="ei-registration">
  <div>
    <div class="button-container">
      <button type="submit" class="close-button" (click)="closeDialog()">
        <mat-icon>close</mat-icon>
      </button>
    </div>
    <ei-heading header="Register" i18n-header></ei-heading>
    <form [formGroup]="form" (ngSubmit)="submit()">
      <span class="account-type" i18n>Account type</span>
      <ei-user-account-selector [editMode]="true" (userSelect)="onUserSelect($event)"></ei-user-account-selector>
      <ei-input formControlName="firstName" placeholder="First name" [optional]="true" i18n-placeholder></ei-input>
      <ei-input formControlName="lastName" placeholder="Last name" [optional]="true" i18n-placeholder></ei-input>
      <ei-input formControlName="email" placeholder="Email" type="email" [optional]="true" i18n-placeholder></ei-input>
      <ei-input
        formControlName="password"
        placeholder="Password"
        [optional]="true"
        type="password"
        i18n-placeholder
      ></ei-input>
      <ei-input
        formControlName="confirmPassword"
        placeholder="Confirm password"
        [optional]="true"
        type="password"
        i18n-placeholder
      ></ei-input>
      <ei-input formControlName="street" placeholder="Street / Number" [optional]="true" i18n-placeholder></ei-input>
      <ei-input formControlName="postalCode" placeholder="Post code" [optional]="true" i18n-placeholder></ei-input>
      <ei-input formControlName="city" placeholder="City" [optional]="true" i18n-placeholder></ei-input>
      <ei-checkbox formControlName="regulationAccepted">
        <div class="regulation">
          <p i18n>Please, accept the<a (click)="navigateToLegalInfoPage()">regulations</a></p>
        </div>
      </ei-checkbox>
      <ei-info-box
        class="info-box"
        text="Please note that the registration process can take up to two working days."
        i18n-text
      ></ei-info-box>
      <ei-button color="primary" [disabled]="form.invalid" [isLoading]="isLoading" i18n>Register</ei-button>
    </form>
    <div class="login-button-container">
      <button class="go-to-login-button" (click)="openLoginPage()" i18n>I already have an account</button>
    </div>
  </div>
</div>
