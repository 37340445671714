import { Component, Input } from '@angular/core';
import { ReferencePreview } from '../../models/reference.model';

@Component({
  selector: 'ei-reference-preview',
  templateUrl: './reference-preview.component.html',
  styleUrls: ['./reference-preview.component.scss'],
})
export class ReferencePreviewComponent {
  @Input() reference: ReferencePreview;
}
