import { Component, Input, ViewEncapsulation } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { OfferPreview } from '../../models/offer.model';

@Component({
  selector: 'ei-offer-preview',
  templateUrl: './offer-preview.component.html',
  styleUrls: ['./offer-preview.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OfferPreviewComponent {
  @Input() offer: OfferPreview;
  @Input() showLink: boolean;
  @Input() tabletPreview: boolean;

  constructor(private _router: Router) {}

  private checkShouldNavigateToExternal(): boolean {
    if (this.offer?.url) {
      return true;
    }

    return false;
  }

  private navigateToExternalPage(): void {
    window.open(`http://${this.offer?.url}`, '_blank');
  }

  redirect(): void | Promise<boolean> {
    if (this.checkShouldNavigateToExternal()) {
      this.navigateToExternalPage();
      return;
    }

    return this._router.navigateByUrl(`/buy/offer/${this.offer.id}?id=${this.offer.id}&title=${this.offer.title}`);
  }
}
