import { Component, Input } from '@angular/core';

@Component({
  selector: 'ei-go-back-link',
  templateUrl: './go-back-link.component.html',
  styleUrls: ['./go-back-link.component.scss'],
})
export class GoBackLinkComponent {
  @Input() pageRoute: string;
  @Input() displayOnMobile = false;
}
