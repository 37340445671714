import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterEvent } from '@angular/router';
import { filter, takeUntil } from 'rxjs/operators';
import { LoginService } from '../../../modules/home/services/login.service';
import { LoginComponent } from '../../../modules/home/components/login/login.component';
import { RegistrationComponent } from '../../../modules/home/components/registration/registration.component';
import { DialogService } from '../../../shared/services/dialog.service';
import { Role } from '../../../shared/models/role.model';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'ei-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent implements OnInit {
  year: number;
  collapsed: { [key: string]: boolean };
  currentRoute: string;
  lang: string;

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this._handleCollapse((event.target as Window).innerWidth);
  }

  constructor(
    private _router: Router,
    private _loginService: LoginService,
    private _dialogService: DialogService,
    private _userService: UserService,
  ) {
    this.year = new Date().getFullYear();
    this.collapsed = {
      buy: true,
      sell: true,
      corporate: true,
      myAccount: true,
      legal: true,
      contact: true,
    };
  }

  ngOnInit(): void {
    this._handleCollapse(window.innerWidth);
    this._router.events
      .pipe(filter((event: RouterEvent): boolean => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd): void => {
        this.currentRoute = event.urlAfterRedirects;

        const urlRoutes = this.currentRoute.split('/');

        this.lang = '';
        if (urlRoutes.length && urlRoutes[1] && urlRoutes[1].split('#')[0] === 'en') {
          this.lang = 'en';
        }
      });
  }

  isLoggedIn = (): boolean => this._loginService.isLoggedIn();

  hasSellerRole = () => this._userService.hasRole(Role.SELLER);

  openLoginDialog = (): void => {
    this._dialogService.open(LoginComponent);
  };

  openRegistrationDialog = (): void => {
    this._dialogService
      .open(RegistrationComponent, { panelClass: 'registration-dialog' })
      .afterClosed()
      .subscribe((data?: { openDialog: boolean }): void => {
        if (data?.openDialog) {
          this.openLoginDialog();
        }
      });
  };

  toggleCollapse = (key: string): void => {
    this.collapsed[key] = !this.collapsed[key];
  };

  getCollapseIcon = (key: string): string => (this.collapsed[key] ? 'arrow_drop_down' : 'arrow_drop_up');

  private _handleCollapse = (width: number): void => {
    width < 768 ? this._collapseMobile() : this._expandAll();
  };

  private _expandAll = (): void => {
    this.collapsed = {
      ...Object.keys(this.collapsed).reduce((reduced, key) => ({ ...reduced, [key]: false }), {}),
    };
  };

  private _collapseMobile = (): void => {
    this.collapsed = {
      buy: false,
      sell: true,
      corporate: true,
      myAccount: true,
      legal: true,
      contact: false,
    };
  };

  getRouterLink(destination: string): string {
    return this.lang ? `/${this.lang}/${destination}` : `/${destination}`;
  }
}
