import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getToken(): string {
    try {
      return localStorage.getItem('token');
    } catch {
      throw Error('The key not found in localStorage');
    }
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  removeToken(): void {
    localStorage.removeItem('token');
  }

  getExp(): number | null {
    try {
      return Number(localStorage.getItem('exp'));
    } catch {
      throw Error('The key not found in localStorage');
    }
  }

  setExp(exp: number): void {
    localStorage.setItem('exp', String(exp));
  }

  removeExp(): void {
    localStorage.removeItem('exp');
  }
}
