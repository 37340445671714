import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Role } from 'src/app/shared/models/role.model';
import { UserProfile } from 'src/app/shared/models/user.model';

@Component({
  selector: 'ei-user-account-selector',
  templateUrl: './user-account-selector.component.html',
  styleUrls: ['./user-account-selector.component.scss'],
})
export class UserAccountSelectorComponent implements OnInit {
  form: FormGroup;

  @Input() profile: UserProfile;
  @Input() editMode: boolean;
  @Input() sellerPending: boolean;

  @Output() userSelect = new EventEmitter<boolean>();

  get sellerIcon(): string {
    return this.form.getRawValue().seller
      ? 'assets/images/icons/account-types/seller-green.png'
      : 'assets/images/icons/account-types/seller-gray.png';
  }

  constructor(private _formBuilder: FormBuilder) {}

  ngOnInit(): void {
    this.form = this._formBuilder.group({
      buyer: { value: true, disabled: true },
      seller: this.profile?.role === Role.SELLER || this.sellerPending,
    });

    this.userSelect.emit(this.sellerSelected());
  }

  toggleSellerSelected = (): void => {
    if (this.editMode && !this.sellerPending) {
      this.form.get('seller').setValue(!this.sellerSelected());
      this.userSelect.emit(this.sellerSelected());
    }
  };

  sellerSelected(): boolean {
    return this.form.get('seller').value;
  }
}
